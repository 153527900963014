import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

import ProgressIndicator from '../../../../../widgets/progressIndicator';
import { ticketingActions } from '../../../../../actions';
import { ReactComponent as GreenTick } from '../../../../../assets/icons/ticketing/green-tick.svg';
import { apiStatus } from '../../../../../constants';
class AddComments extends React.Component {

  // renderMedicalDetailsCard = () => {
  //   const { ticketing } = this.props;

  //   return (
  //     <>
  //       <div className={css.add_comments}>

  //     </>
  //   )
  // }

  componentDidMount() {
    const { reason, comment, addCommentsToTicket, resetAddComment } = this.props;
    const { ticketing } = this.props;
    const { selectedTicket } = ticketing;
    resetAddComment();
    addCommentsToTicket(selectedTicket ? selectedTicket.id : '', reason, comment)
  }


  renderGreenTick = () => {
    return (
      <div className={css.green_tick_container}>
        <GreenTick className={css.green_tick_container_image} />
      </div>
    )
  }

  renderAcknowledgement = () => {
    return (
      <div className={css.acknowledgementContainer}>
        {this.renderGreenTick()}
        <p className={css.acknowledgementContainer_text}>
          Thanks! Click ‘Done’ to go to the next ticket.
        </p>
      </div>
    )
  }

  render() {
    const { ticketing } = this.props;
    const { addCommentLoading, addCommentResponse, markResolveLoading } = ticketing;
    return (<div>
      {(addCommentLoading || markResolveLoading) ?


        <>
          <ProgressIndicator />
        </>
        :
        <>{addCommentResponse === apiStatus.SUCCESS ? <>
          {this.renderAcknowledgement()}
        </> : <> Something went wrong !! </>
        }</>}
    </div>)
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCommentsToTicket: (ticketId, reason, comment) => { dispatch(ticketingActions.addCommentsToTicketEvent(ticketId, reason, comment)) },
    resetAddComment: () => { dispatch(ticketingActions.resetAddCommentEvent()) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddComments);