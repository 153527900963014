import React from 'react';
import { connect } from 'react-redux';
import { withStyles, CircularProgress } from '@material-ui/core';
import TicketingStatusBar from './ticketingStatusBar';
import TicketDetails from './ticketDetails';
class TicketingTask extends React.Component {
    constructor() {
        super();
        this.state = {
            itemsPerPage: 10,
        }
    }

    ProgressIndicator = () => <CircularProgress color="primary" style={{
        left: '47%',
        top: '50%',
        position: 'absolute',
    }} disableShrink />;


    render() {
        return (<div>
            <TicketingStatusBar />
            <TicketDetails />
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, pwaDetails } = state;
    return { auth, pwaDetails };
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

const ConnectedTicketingTask = connect(mapStateToProps, mapDispatchToProps)(TicketingTask);
export default withStyles({ withTheme: true })(ConnectedTicketingTask);
