import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

class MiniInfoTile extends React.Component {

  render() {
    return (
      <div className={css.mini_info_tile} >

        <div className={css.mini_info_tile_image} >{this.props.image}

        </div>
        <div className={css.mini_info_tile_text}>
        {this.props.text}
        </div>

      </div>
    )
  }
}

export default connect(null, null)(MiniInfoTile);