import { Divider, Drawer, Radio } from '@material-ui/core';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ticketingActions } from '../../../../../actions';
import ConfirmationRadio from '../../confirmationRadios';
import css from './style.module.scss';
import { getISTFormat2 } from '../../../../../common/utils';
import { ReactComponent as GreenTick } from '../../../../../assets/icons/ticketing/tick-round-green.svg';

class PrescriptionStep extends Component {

  renderLine = (text) => {
    return (
      <p className={css.follow_up_details_bottom_paragraph_line}>
        {text}
      </p>
    )
  }

  renderPrescriptionStep = () => {
    const { prescriptionRecieved, setPrescriptionRecievedState, resetPrescriptionRecievedState } = this.props;
    return (
      <div className={css.follow_up_details_bottom_compartment_2}>
        <div className={css.line}>
          {this.renderLine("Did the patient receive the prescription?")}
          <ConfirmationRadio affirmative={prescriptionRecieved} set={setPrescriptionRecievedState} reset={resetPrescriptionRecievedState} />
        </div>
        <div className={css.follow_up_details_bottom_compartment_2_right}>
        </div>
      </div>
    )
  }

  handleSendPrescriptionOnSms = () => {
    const { sendPrescriptionLinkOnSms, ticketing } = this.props;
    const phoneNumber = ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? '';
    sendPrescriptionLinkOnSms(phoneNumber);
  }

  handleSendPrescriptionOnWhatsapp = () => {
    const { sendPrescriptionLinkOnWhatsapp, ticketing } = this.props;
    const phoneNumber = ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? '';
    sendPrescriptionLinkOnWhatsapp(phoneNumber);
  }

  handleDoneClick = () => {
    const { resetPrescriptionLinkStatus, closeSendPrescriptionDrawer } = this.props;
    resetPrescriptionLinkStatus();
    closeSendPrescriptionDrawer();
  }

  renderSendPrescriptionDrawer = () => {
    const { showPrescriptionDrawer, closeSendPrescriptionDrawer, ticketing } = this.props;
    return (
    <Drawer disableEnforceFocus anchor="right" open={showPrescriptionDrawer} onClose={closeSendPrescriptionDrawer}>
      <div style={{ width: '320px', height: '100vh' }} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <p style={{ color: 'black', fontWeight: 'bold', fontSize: '16px', fontFamily: 'Manrope-Bold', marginTop: '26px', marginLeft: '16px', marginBottom: '23px' }}>Send Prescription</p>
          </div>
          <Divider style={{ backgroundColor: 'grey' }} />
          {
             !(ticketing?.sendPrescriptionLinkSmsApiStatus?.status === 'success' || ticketing?.sendPrescriptionLinkWhatsappApiStatus?.status === 'success') && (
              <>
                  <div className={css.prescriptionInfoContainer}>
                    <p className={css.prescriptionInfoContainerTitle}>Last Issued Prescription</p>
                    <p className={css.prescriptionInfoContainerContent}>Generated on:{' '}
                    {
                      getISTFormat2(ticketing?.selectedTicket?.metadata?.consultation?.created_at ?? '')
                    }
                    </p>
                  </div>
                  <div className={css.userSelectionCard} style={{ border: '2px solid #0033CC'}}>
                    <div className={css.userSelectionCardHeader}>
                      <p className={css.userName}>{ticketing?.selectedTicket?.metadata?.consultation?.customer?.name ?? ''}</p>
                      <Radio
                        checked={true}
                        value={ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? ''}
                        color="primary"
                      />
                    </div>
                    <div className={css.userNumberContainer}>
                      <p className={css.userNumberText}>{ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? ''}</p>
                    </div>
                  </div>
                  <div className={css.btnContainer}>
                    <div className={css.btnTitleContainer}>
                        <p className={css.btnTitleContainerText}>Send Prescription Via</p>
                        <span className={css.rightLine}></span>
                    </div>
                    <div className={css.btnGroup}>
                      <button className={css.smsBtn} style={{ width: '100%', flex: `${ticketing?.getCustomerPreferencesApiStatus?.data?.whatsapp?.consent === "granted" ? 0.45 : 1 }` }} onClick={this.handleSendPrescriptionOnSms}>SMS</button>
                      {
                        ticketing?.getCustomerPreferencesApiStatus?.data?.whatsapp?.consent === "granted" &&
                        <button className={css.whatsappBtn} onClick={this.handleSendPrescriptionOnWhatsapp}>WhatsApp</button>
                      }
                    </div>
                  </div>
              </>
            )
          }
          {
            (ticketing?.sendPrescriptionLinkSmsApiStatus?.status === 'success' || ticketing?.sendPrescriptionLinkWhatsappApiStatus?.status === 'success') && (
                <>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', flexDirection: 'column'}}>
                  <GreenTick style={{ width: '54px', height: '54px' }} />
                  <p className={css.successText}>Prescription sent successfully!</p>
                </div>
                  <div className={css.btnContainer}>
                          <button className={css.doneBtn} onClick={this.handleDoneClick}>Done</button>
                  </div>
                </>
            )
          }
      </div>                    
    </Drawer>
    )
  }  

  render() {
    return (
      <>
        {this.renderPrescriptionStep()}
        {this.renderSendPrescriptionDrawer()}
      </>
    )
  }
}
const mapStateToProps = (state) => {
   return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing, consultationDetails: state.consultationDetails };
};
  
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerPreferences: () => { dispatch(ticketingActions.getCustomerPreferencesEvent()) },
    sendPrescriptionLinkOnSms: (phoneNumber) => { dispatch(ticketingActions.sendPrescriptionLinkThroughSmsEvent(phoneNumber)) },
    sendPrescriptionLinkOnWhatsapp: (phoneNumber) => { dispatch(ticketingActions.sendPrescriptionLinkThroughWhatsappEvent(phoneNumber)) },
    resetPrescriptionLinkStatus: () => { dispatch(ticketingActions.resetSendPrescriptionLinkSmsApiStatus()) },
    resetPlaystoreLinkStatus: () => { dispatch(ticketingActions.resetSendPlaystoreLinkApiStatus()) },
 }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionStep);