/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import css from './style.module.scss';

function BlueButton({ onClick, text, disabled = false }) {
  const content = (
    <>
      <button onClick={onClick} className={disabled ? css.button_disabled : css.button}>
        {text}
      </button>
    </>
  );
  return content;
}

export default BlueButton;
