import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';

import { Typography, Drawer, Paper, CircularProgress, Button, Divider, TextField, MenuItem } from '@material-ui/core';
import 'date-fns';

import { consultationType, consultationStatus } from '../../common/constants';
import { getISTTimeFormat, getAgeUnit } from '../../common/utils';
import { getConsultationsByClinicId, setPresentableConsultationQueue, getConsultationsByClinicIdFirstPage, resetConsultationQPageNumber, resetConsultationById, resetAddVitalsStatus, cancelConsultationById, resetCancelConsultationStatus, getAllDoctors, resetConsultationDetail, resetDoctorByConsultationId, assignDoctorByConsultationId } from '../../actions';
import { HEADQUARTERS_ID, accessRole, apiResponse } from '../../common/constants';
import { errors } from '../../common/errors';

import { ReactComponent as BookingConfirmed } from '../../images/BookingConfirmed.svg';
import { ReactComponent as Location } from '../../images/Location.svg';
import { ReactComponent as BookingFailed } from '../../images/BookingFailed.svg';
import moment from 'moment';

const ASSIGN_DRAWER_STATES = {
    INIT: 'init',
    CONFIRMATION: 'confirmation',
    LOADING: 'loading',
    ASSIGN_DOCTOR_API_CALLED: 'assignDoctorApiCalled',
}
class ParticularPendingC extends React.Component {

    constructor() {
        super();
        this.state = {
            numberOfCharacters: 75,
            doctor: '',
            assignDrawer: false,
            mousePonterInside: false,
            assignDrawerContent: ASSIGN_DRAWER_STATES.INIT,
        };
    }

    componentDidMount() {
        this.props.resetConsultationDetail();
        this.props.resetDoctorByConsultationId();
    };

    componentDidUpdate(prevProps) {
        const { consultationDetails } = this.props;
        const { consultationDetails: oldConsultationDetails } = prevProps;
        if (consultationDetails !== oldConsultationDetails
            && consultationDetails.assignDoctorStatus !== oldConsultationDetails.assignDoctorStatus) {
                this.setState({
                    assignDrawerContent: ASSIGN_DRAWER_STATES.ASSIGN_DOCTOR_API_CALLED,
                })
                if (consultationDetails.assignDoctorStatus !== errors.ERROR_WHILE_ASSIGNING_DOCTOR) {
                    this.closeAssignDoctorDrawer(true);
                }
            }
    }

    onHover = () => {
        this.setState({ mousePonterInside: true })
    }

    outsideCard = () => {
        this.setState({ mousePonterInside: false })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    getPendingConsultationsFirstPage = () => {
        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();
        if (this.props.auth && this.props.auth.currentClinicLocation && this.props.auth.currentClinicLocation.id) {
            const consultationQueuePromise = this.props.getConsultationsByClinicIdFirstPage(this.props.auth.currentClinicLocation.id, consultationStatus.PENDING, 1, 'desc', startDate, endDate);
            consultationQueuePromise.then(() => {
                if (this.props.consultationDetails && this.props.consultationDetails.consultationQueue.consultations) {
                    this.props.setPresentableConsultationQueue(this.props.consultationDetails.consultationQueue.consultations)
                }
            })
        }
    }

closeAssignDoctorDrawer = (autoCloseFlag) => {
        const {
            resetDoctorByConsultationId, resetConsultationQPageNumber,
            resetConsultationById,
        } = this.props;
        setTimeout(() => {
            this.setState({
                assignDrawerContent: ASSIGN_DRAWER_STATES.INIT,
                assignDrawer: false,
            }, () => {
                resetDoctorByConsultationId();
                resetConsultationQPageNumber();
                resetConsultationById();
            });
        }, autoCloseFlag === true ? 2400 : 0);
    }


    pressAssignDoctor = () => {
        this.props.getAllDoctors(true);  //get a list of only available doctors
        this.setState({ assignDrawer: true });
    }

    clickConfirmAssignDoctor = () => {
        this.setState({ assignDrawerContent: ASSIGN_DRAWER_STATES.CONFIRMATION });
    }

    clickYesForDoctorAssignment = () => {
        const { assignDoctorByConsultationId, consultation } = this.props;
        const { doctor } = this.state;
        this.setState({ assignDrawerContent: ASSIGN_DRAWER_STATES.LOADING }, () => {
            assignDoctorByConsultationId(consultation.id, doctor);
        });
    }

    clickNoForDoctorAssignment = () => {
        this.setState({ assignDrawerContent: ASSIGN_DRAWER_STATES.INIT });
    }

    clickTryAgain = () => {
        this.resetConsultationDetails();
        this.props.resetDoctorByConsultationId();
    }

    callAssignDoctorApi = () => {
        this.setState({
            assignDrawerContent: ASSIGN_DRAWER_STATES.ASSIGN_DOCTOR_API_CALLED
        });
    }

    renderConsultationCard = () => {
        const consultation = this.props.consultation;
        return (
            <div
                onMouseOver={this.onHover} onMouseLeave={this.outsideCard} style={{
                    marginLeft: '2rem', marginRight: '2rem', borderWidth: '1px 1px 1px 1px', width: '85vw',
                    borderColor: '#cbcbcb',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                    marginBottom: '3vh',
                    position: 'relative',
                    paymentMethod: ''
                }}>
                <div style={{
                    margin: '1.5vh',
                    marginBottom: '0.5vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        {(consultation && consultation.customer && consultation.customer.name) ? <Typography
                            style={{ float: 'left', marginRight: '0.5vw', display: 'inline-flex', color: '#172a3a', fontFamily: 'Rubik-Medium', fontSize: '1.5vh', marginTop: '1.1vh' }}
                        >
                            {consultation.customer.name}
                        </Typography> : <> </>}
                        {this.props.consultation && this.props.consultation.customer && (this.props.consultation.customer.age || this.props.consultation.customer.age === 0) ? <Typography
                            style={{ float: 'left', marginRight: '0.5vw', marginTop: '1.1vh', display: 'inline-flex', color: '#172a3a', fontFamily: 'Rubik', fontSize: '1.5vh' }}
                        >
                            <span style={{ marginRight: '0.5vw' }}>
                                |
                        </span>
                            {
                                + this.props.consultation.customer.age + ' ' + getAgeUnit(this.props.consultation.customer.age_unit)}
                        </Typography> : <> </>}
                        {this.props.consultation && this.props.consultation.customer && this.props.consultation.customer.contact_number && this.props.consultation.customer.lop ? <Typography
                            style={{ float: 'left', marginRight: '5vw', marginTop: '1.1vh', display: 'inline-flex', color: '#172a3a', fontFamily: 'Rubik', fontSize: '1.5vh', posiiton: 'absolute' }}
                        >
                            <span style={{ marginRight: '0.5vw' }}>
                                |
                        </span>
                            {this.props.consultation.customer.contact_number}
                            <span style={{ marginLeft: '0.5vw' }}>
                                |
                        </span>
                            <span style={{ marginLeft: '0.5vw', textTransform: 'capitalize' }}>
                                {this.props.consultation.customer.lop}
                            </span>
                        </Typography> : <> </>}
                    </div>
                    <div>
                        {consultation && consultation.type === consultationType.TELE ? <Typography style={{ fontSize: '1.1vh', marginTop: '0.1vh', color: '#4D4D4D', marginBottom: '1.5vh' }}>(Tele-consultation)</Typography> : <div style={{ marginBottom: '1.8vh' }}></div>}  {/* Show the text Tele-consultation if the consultation type = 'tele' */}
                    </div>

                    {(consultation && consultation.summary) ? <Typography
                        style={{ color: '#172a3a', fontFamily: 'Rubik', fontSize: '1.5vh', position: 'absolute', left: '25vw', top: '2.5vh' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {_.truncate(consultation.summary, {
                                'length': this.state.numberOfCharacters,
                                'separator': ' '
                            })}
                        </div>
                    </Typography> : <> </>}
                    {consultation.created_at ? <div style={{ color: 'black', fontFamily: 'Rubik-light', fontSize: '1.5vh', position: 'absolute', left: '60vw', top: '2.7vh' }}>{
                        getISTTimeFormat(consultation.created_at)
                    }
                        <span style={{ fontFamily: 'Rubik-Regular' }}>
                        </span>
                    </div> : <> </>}
                    <div style={{ display: 'flex', paddingLeft: '53vw', marginTop: this.props.consultation.type === consultationType.PHYSICAL ? '-0.1vh' : '-2vh', marginBottom: '2.7vh', position: 'relative' }}>
                        {(this.state.mousePonterInside && (this.props.auth && this.props.auth.currentClinicLocation && this.props.auth.currentClinicLocation.id === HEADQUARTERS_ID) && (this.props.consultation && this.props.consultation.type === consultationType.TELE) && (this.props.auth && this.props.auth.authDetails && !_.isNil(_.find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER })))) ? 
                            <Button onClick={this.pressAssignDoctor}
                            variant="contained" style={{ backgroundColor: '#FACA60', position: 'absolute', top: '-3.1vh', right: '2vw' }}>
                            <Typography style={{ fontFamily: 'Rubik', fontSize: '1.3vh', textTransform: 'capitalize', color: 'white' }}> Assign Doctor</Typography>
                            </Button> : <> </>
                        }
                    </div>
                </div>
            </div>
        );
    };

    resetConsultationDetails = () => {
        this.setState({ assignDrawerContent: ASSIGN_DRAWER_STATES.INIT });
        this.props.resetConsultationDetail();
    };


    getAllDoctorsList = () => {
        const doctorList = [];
        const doctorListFromApi = this.props.consultationDetails && this.props.consultationDetails.allDoctors
            ? this.props.consultationDetails.allDoctors.doctors : [];
        _.forEach(doctorListFromApi, (doctorDetail) => {
            doctorList.push({ value: doctorDetail.id, label: doctorDetail.last_name ? doctorDetail.first_name + ' ' + doctorDetail.last_name : doctorDetail.first_name })
        })
        return doctorList;
    }

    renderAssignDoctorDrawer = () => {
        const doctorList = this.getAllDoctorsList();
        const { assignDrawerContent } = this.state;
        if (assignDrawerContent === ASSIGN_DRAWER_STATES.INIT) {
            return (
                <div>
                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Assign Doctor</Typography>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <TextField
                        inputProps={{ style: { fontFamily: 'Rubik', color: 'black' } }}
                        disabled
                        value={_.truncate(this.props.consultation.summary, 15)}
                        label="Summary"
                        style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem' }} />
                    <TextField
                        inputProps={{ style: { fontFamily: 'Rubik', color: 'black' } }}
                        disabled
                        value={this.props.consultation.customer.contact_number}
                        label="Contact Number"
                        style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem' }} />
                    <TextField
                        inputProps={{ style: { fontFamily: 'Rubik', color: 'black' } }}
                        style={{ width: '11rem', marginLeft: '2vh', marginBottom: '1vh', marginTop: '2rem' }}
                        label="LOP"
                        disabled
                        value={_.capitalize(this.props.consultation.customer.lop)}
                        margin="normal"
                    >
                    </TextField>
                    <div style={{ backgroundColor: 'white', marginTop: '2vh', marginLeft: '1rem' }}>
                        <hr style={{ marginLeft: '-2vh', color: '#ffffff' }} />

                    </div>
                    <TextField
                        style={{ display: 'flex', width: '11rem', marginTop: '3vh', marginLeft: '1.3vw' }}
                        select
                        label="Choose Doctor"
                        value={this.state.doctor}
                        onChange={this.handleChange('doctor')}
                        margin="normal"
                    >
                        {doctorList.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <div style={{ display: 'flex', float: 'left', width: '20vw', marginTop: '0.5vh', marginLeft: '1vw' }} >
                        <Location style={{ marginTop: '1.2vh' }} />
                        <Typography style={{ textTransform: 'uppercase', fontFamily: 'Rubik-Medium', color: '#172a3a', marginLeft: '0.5vh', marginTop: '1.4vh', fontSize: '1.9vh' }}>{this.props.auth && this.props.auth.currentClinicLocation ? this.props.auth.currentClinicLocation.name : 'loading...'}</Typography>

                    </div>
                    <div style={{
                        position: 'fixed',
                        bottom: '0px',
                        right: '0px', width: '50vh'
                    }}>

                        <Button
                            onClick={this.clickConfirmAssignDoctor}
                            disabled={!this.state.doctor}
                            style={{
                                backgroundColor: !this.state.doctor ? 'grey' : 'orange',
                                color: 'white',
                                width: '100%', height: '3rem', textTransform: 'capitalize'
                            }}> Confirm </Button>
                    </div>
                </div>
            );
        } else if (assignDrawerContent === ASSIGN_DRAWER_STATES.CONFIRMATION) {
            return (
                <div>
                    <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '80%', marginLeft: '35%', marginBottom: '3rem' }}> Are you sure ?</Typography>
                    <div style={{ marginBottom: '30%', marginLeft: '23%', marginRight: '20%' }}>
                        <Button onClick={this.clickNoForDoctorAssignment} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '40%' }}>No</Button>
                        <Button onClick={this.clickYesForDoctorAssignment} variant="outlined" style={{ marginLeft: '15%', color: 'orange', borderColor: 'orange', width: '40%' }} >Yes</Button>
                    </div>
                </div>
            );
        } else if (assignDrawerContent === ASSIGN_DRAWER_STATES.LOADING) {
            return (
                <div> <CircularProgress style={{ color: 'orange', marginLeft: '11rem', marginTop: '23.5rem', marginBottom: '23rem' }} disableShrink /> </div>
            );
        } else if (assignDrawerContent === ASSIGN_DRAWER_STATES.ASSIGN_DOCTOR_API_CALLED) {
            if (this.props.consultationDetails && (this.props.consultationDetails.assignDoctorStatus === errors.ERROR_WHILE_ASSIGNING_DOCTOR)) {
                return (
                    <>
                        <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Assign Doctor</Typography>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                            <BookingFailed style={{ marginLeft: '43%', marginRight: '43%' }} />
                            <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '27%', marginRight: '20%' }}> Error Submitting the Form </Typography>
                            <Button onClick={this.clickTryAgain} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '30%', textTransform: 'capitalize', marginLeft: '35%', marginRight: '35%', marginTop: '2rem' }}>Try Again</Button>
                        </div>
                    </>
                )
            } else if (this.props.consultationDetails && (this.props.consultationDetails.assignDoctorStatus === apiResponse.DOCTOR_ASSIGNED)) {
                return (
                    <div style={{ marginTop: '90%', marginBottom: '30%' }}>
                        <BookingConfirmed style={{ marginLeft: '45%' }} />
                        <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '36%' }}> Doctor assigned</Typography>
                    </div>
                )
            }
        }
    }

    render() {
        const { position } = this.props;
        // TO DO : auto closing of drawer when payment is done
        // if (this.props.consultationDetails && this.props.consultationDetails.offlinePaymentStatus && this.props.consultationDetails.offlinePaymentStatus.payments_group_id) {
        //     setTimeout(this.closePaymentsDrawer, 2000); //This is to auto closing of the drawer
        // }
        return (
            <div className={`pendingConsultaion-${position}`}>
                {this.renderConsultationCard()}
                < Drawer anchor="right" open={this.state.assignDrawer} onClose={() => this.closeAssignDoctorDrawer(false)}>

                    <Paper style={{ width: '50vh', height: '100%' }}>
                        {this.renderAssignDoctorDrawer()}
                    </Paper>
                </Drawer>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
};

export default connect(mapStateToProps, { getConsultationsByClinicId, setPresentableConsultationQueue, getConsultationsByClinicIdFirstPage, resetConsultationQPageNumber, resetConsultationById, resetAddVitalsStatus, cancelConsultationById, resetCancelConsultationStatus, getAllDoctors, resetConsultationDetail, resetDoctorByConsultationId, assignDoctorByConsultationId })(ParticularPendingC);