/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import css from './style.module.scss';
import { ReactComponent as TickBlue } from '../../assets/icons/ticketing/tick-blue.svg';

function Stepper({ currentStep = 1, steps }) {
  const content = (
    <div className={css.module}>
      {steps.map((item, index) => {
        return (<div className={css.container} key={index}>
          <div className={index + 1 <=  currentStep ? ((index + 1 === currentStep) ? css.container_step_active : css.container_step_done) : css.container_step}>
            {index + 1 < currentStep ?
              <TickBlue className={css.icon} />
              :
              index + 1
            }
            <div className={css.helper_text}>{item}</div>
          </div>
          {steps.length - 1 !== index && <div className={css.container_step_divider} />}
        </div>
        )
      })}

    </div>
  );
  return content;
}

export default Stepper;
