import React from 'react';
import { connect } from 'react-redux';

import { ReactComponent as ClockOrange } from '../../../../assets/icons/ticketing/clock-orange.svg';
import { ReactComponent as TickRoundGreen } from '../../../../assets/icons/ticketing/tick-round-green.svg';

import css from './style.module.scss';
import { ticketType } from '../../../../constants';

class TicketingStatusTile extends React.Component {

  getTileIcon = () => {
    const { ticket } = this.props;
    if (ticket === ticketType.RESOLVED) {
      return <TickRoundGreen />;
    } else {
      return <ClockOrange />
    }

  };

  render() {
    const { ticket } = this.props;
    return (
      <div className={ticket === ticketType.RESOLVED ? css.ticketing_statusbar_tile_green : css.ticketing_statusbar_tile_yellow}>
        <div className={css.ticketing_statusbar_tile_icon}>{this.getTileIcon()}</div>
        <p className={css.ticketing_statusbar_tile_text1}>{this.props.tileText1}</p>
        <p className={ticket === ticketType.RESOLVED ? css.ticketing_statusbar_tile_text2_green : css.ticketing_statusbar_tile_text2_yellow}>{this.props.tileText2}</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
};

export default connect(mapStateToProps, null)(TicketingStatusTile);