import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

class RadiosVertical extends React.Component {

  render() {
    const { options, selectedOption, selectOption } = this.props;
    return (
      <div className={css.box}>
        {options.map((option) => {
          return (
            <div className={css.container}
              key={option.key}
              onClick={() => selectOption(option.value)}
            >
              <input type="radio" id={option.key} name={option.key} checked={option.value === selectedOption} />
              <label className={option.value === selectedOption ? css.container_label_active : css.container_label_inactive} htmlFor="yes" >{ option.key }</label>
            </div>
          )
        }
        )}
      </div>
    )
  }
}

export default connect(null, null)(RadiosVertical);