import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

class InputSelector extends React.Component {

  handleChange = (event) => {
    const { selectOption } = this.props;
    selectOption(event.target.value);
  }

  render() {
    const { info, list, selectedOption, specialType = '' } = this.props;
    return (
      <div className={css.container}>
        <form>
          <select className={css.container_selector}
            onChange={this.handleChange}>
            {specialType === 'doctor' ?
              <option className={css.container_selector_option} value={selectedOption}>{selectedOption && list.length > 0 ? list.filter((item) => item.value === Number(selectedOption))[0].label : info}</option>
              :
              <option className={css.container_selector_option} value={selectedOption}>{selectedOption ? selectedOption.replace('_', ' ') : info}</option>
            }

            {list &&
              list.map((item) => {
                if (specialType === 'doctor') {
                  return (
                    <option
                      key={item.value}
                      value={item.value}>{typeof item.label === 'string' ? `Dr. ${item.label.replace('_', ' ')}` : ''}</option>
                  )
                }
                return <option
                  key={item}
                  value={item}>{typeof item === 'string' ? item.replace('_', ' ') : ''}</option>
              })
            }
          </select>
        </form>
      </div>
    )
  }
}

export default connect(null, null)(InputSelector);