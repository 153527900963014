import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';
import ConfirmationRadios from '../../confirmationRadios';
import { ticketingActions } from '../../../../../actions';
import ProgressIndicator from '../../../../../widgets/progressIndicator';
import { apiStatus, ticketObjective } from '../../../../../constants';

class FollowUpConsultation extends React.Component {

  componentDidMount() {
    const { reason, comment, addCommentsToTicket, ticketing, resetMarkResolve, resetAddComment } = this.props;
    const { selectedTicket } = ticketing;
    resetAddComment();
    if (selectedTicket?.objective === ticketObjective.PATIENT_FOLLOW_UP) {
      addCommentsToTicket(selectedTicket ? selectedTicket.id : '', reason, comment);
    }
    resetMarkResolve(); 
  }


  renderLine = (text) => {
    return (
      <p className={css.line}>
        {text}
      </p>
    )
  }

  renderFollowUpConsultation = () => {
    const { consultationNeeded, setNeedConsultation, resetNeedConsultation } = this.props;
    return (
      <div className={css.follow_up_details_bottom_compartment_2}>
        <div className={css.follow_up_details_bottom_compartment_2_left}>
          {this.renderLine("Does the patient want a Follow up consultation?")}
          <ConfirmationRadios affirmative={consultationNeeded} set={setNeedConsultation} reset={resetNeedConsultation} />
        </div>
        <div className={css.follow_up_details_bottom_compartment_2_right}>
        </div>
      </div>
    )
  }

  render() {
    const { ticketing } = this.props;
    const { addCommentLoading, addCommentResponse, markResolveLoading, selectedTicket } = ticketing;
    return (<div>
      {(addCommentLoading || markResolveLoading) ?
        <>
          <ProgressIndicator />
        </>
        :
        <>{(addCommentResponse === apiStatus.SUCCESS && selectedTicket?.objective === ticketObjective.PATIENT_FOLLOW_UP) 
          || (selectedTicket?.objective === ticketObjective.PATIENT_FEEDBACK) 
        ? <>
          {this.renderFollowUpConsultation()}
        </> : <> Something went wrong !! </>
        }</>}
    </div>)
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCommentsToTicket: (ticketId, reason, comment) => { dispatch(ticketingActions.addCommentsToTicketEvent(ticketId, reason, comment)) },
    resetMarkResolve: () => { dispatch(ticketingActions.resetMarkResolveEvent()) },
    resetAddComment: () => { dispatch(ticketingActions.resetAddCommentEvent()) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpConsultation);