import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ServiceCard from "./ServiceCard";
import MiddleTab from "./MiddleTab";
import { ReactComponent as NoPlans } from "../../../images/NoPlans.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-icon-one.svg";
import { ReactComponent as NoMandateIllustration } from "../../../assets/icons/no-mandate-illustration.svg";

import { subscriptionStatus, paymentMultiplier } from "../../../common/constants";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import { withStyles } from "@material-ui/core/styles/";
import {
  getInvoices,
  resetInvoiceDetails,
  resetOrderDetails,
  closeViewDetails,
  getPlanBillingPrefEvent,
  getCustomerDetails,
  resetOpdAmounts,
  resetOpdTransactions,
} from "../../../actions";
import { updateSubscription } from "../../../actions/familyAction";
import InvoiceList from "./InvoiceList";
import styles from "./plansStyle";
import SendLinkDrawer from "../../common/SendLinkDrawer/SendLinkDrawer";
import {
  formatNumberAsIndianCurrency,
  getISTFormat2,
  getSubscriptionProductName,
  splitAndCapitaliseString,
} from "../../../common/utils";
import { Box, Divider, Drawer, Tooltip, CircularProgress, Chip } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { strings } from "../../../constants";
import { getSubscriptionDetails } from "../../../clients/subscriptionService";
import { color } from "../../../constants/colors";
import { reconOrderOrInvoiceById } from "../../../clients/invoiceService";
import history from "../../../history";
import { tncStatus } from "../../../common/constants";

const moment = require("moment-timezone");

function Alert(props) {
  return <MuiAlert {...props} />;
}

function getSubscriptionDurationLabel(subscription) {
  switch (subscription.payment_multiplier) {
    case paymentMultiplier.MONTHLY: {
      return "1 Month";
    }
    case paymentMultiplier.QUARTERLY: {
      return "3 Months";
    }
    case paymentMultiplier.SEMI_ANNUALLY: {
      return "6 Months";
    }
    case paymentMultiplier.ANNUALLY: {
      return "1 Year";
    }
    default: {
      return "";
    }
  }
}

function getOptionalAddonsDescriptionForSubscription(subscription) {
  if (!subscription) return "-";
  if (subscription.version === 0 && subscription.addons) {
    return `with: Super Top Up (${formatNumberAsIndianCurrency(
      subscription.product.addons?.coverage
    )})`;
  }
  if (subscription.version === 1) {
    let name = "With: ";
    if (subscription?.addons) {
      const optionalAddons = subscription.addons.filter((addon) => addon.optional);
      optionalAddons.forEach((addon, index) => {
        if (index < subscription.addons.length - 1) {
          name += `${index === 0 ? " " : ", "} ${splitAndCapitaliseString(
            addon.category
          )} (${formatNumberAsIndianCurrency(addon.coverage)})`;
        } else {
          name += ` and ${splitAndCapitaliseString(addon.category)} (${formatNumberAsIndianCurrency(
            addon.coverage
          )})`;
        }
      });
    }
    return name;
  }
}

class Plans extends React.Component {
  state = {
    subscriptionIndex: 0,
    hoverColor: "",
    selectedFontDetail: "",
    activeTabIndex: 0,
    openSendLinkDrawer: false,
    currentPage: 1,
    totalItemToFetch: 20,
    totalItems: 0,
    isFetchingSubscriptions: true,
    isCopied: false,
    isUpiIDCopied: false,
    openSendCoiLinkDrawer: false,
    openDocumentUploadDrawer: false,
    reconcilingInvoices: false,
  }; // change back to 0

  allSubscriptions = [];

  async componentDidMount() {
    const { totalItemToFetch, currentPage } = this.state;
    this.props.resetOrderDetails();
    this.props.resetOpdAmounts();
    this.props.resetOpdTransactions();
    this.props.closeViewDetails();
    const subscriptions = await this.fetchSubscription(currentPage);
    this.allSubscriptions = subscriptions.subscriptions;
    this.setState({
      totalItems: subscriptions.total_count,
      isFetchingSubscriptions: subscriptions.total_count <= totalItemToFetch ? false : true,
    });
  }

  clickMember = (index) => {
    // const subscriptions = this.props.familyDetails.allSubscriptions ? this.props.familyDetails.allSubscriptions.subscriptions : '';
    this.setState({ subscriptionIndex: index });
    // const accessToken = this.props.auth.authDetails.data.access_token;
    // const familyId = subscriptions[index].family.id;
    // const productId = subscriptions[index].product.id;
    // this.props.getPlanBillingPrefEvent(accessToken, familyId, productId);
    // this.props.resetInvoiceDetails();
  };

  renderProductName = (index, isSubscriptionStatusValid, subscription) => {
    const { classes } = this.props;
    if (
      index !== this.state.subscriptionIndex &&
      subscription.status === subscriptionStatus.ACTIVE
    ) {
      return (
        <Typography
          style={{ fontSize: "15px", marginLeft: "3vh", color: "black" }}
          className={classes.productName}
        >
          {subscription.product?.name}
        </Typography>
      );
    } else if (
      index === this.state.subscriptionIndex &&
      subscription.status === subscriptionStatus.ACTIVE
    ) {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            marginLeft: "3vh",
            color: "black",
          }}
        >
          {subscription.product?.name}
        </Typography>
      );
    } else if (index === this.state.subscriptionIndex && isSubscriptionStatusValid) {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            marginLeft: "3vh",
            color: "grey",
          }}
        >
          {subscription.product?.name}
        </Typography>
      );
    } else if (index === this.state.subscriptionIndex && !isSubscriptionStatusValid) {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            marginLeft: "3vh",
            color: "black",
          }}
        >
          {subscription.product?.name}
        </Typography>
      );
    } else if (isSubscriptionStatusValid) {
      return (
        <Typography style={{ fontSize: "15px", marginLeft: "3vh", color: "grey" }}>
          {subscription.product?.name}
        </Typography>
      );
    } else if (!isSubscriptionStatusValid) {
      return (
        <Typography style={{ fontSize: "15px", marginLeft: "3vh", color: "black" }}>
          {subscription.product?.name}
        </Typography>
      );
    }
  };

  fetchSubscription = async (currentPage) => {
    const { totalItemToFetch } = this.state;
    const { auth, familyDetails } = this.props;
    const ACCESS_TOKEN = auth.authDetails.data.access_token;
    const response = await getSubscriptionDetails(
      ACCESS_TOKEN,
      familyDetails.currentCustomer.family.id,
      { page: currentPage, size: totalItemToFetch }
    );
    return response.data;
  };

  renderAppBar = () => {
    const { classes } = this.props;
    const { activeTabIndex, reconcilingInvoices } = this.state;
    return (
      <Paper className={classes.root} elevation={0}>
        <div>
          <AppBar
            position="static"
            elevation={0}
            style={{
              minWidth: "35.5rem",
              display: "flex",
              flexDirection: "row",
              background: "white",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={activeTabIndex}
              onChange={this.handleChange}
              style={{
                textTransform: "capitalize",
                backgroundColor: "white",
                fontWeight: "bold",
              }}
              classes={{ indicator: classes.indicator, root: classes.tab }}
            >
              <Tab
                className={activeTabIndex === 0 ? classes.active_tab : classes.default_tabStyle}
                style={{ color: "black", textTransform: "capitalize" }}
                label="Services"
              />
              <Tab
                className={activeTabIndex === 1 ? classes.active_tab : classes.default_tabStyle}
                style={{ color: "black", textTransform: "capitalize" }}
                label="Invoices"
              />
              <Tab
                className={activeTabIndex === 2 ? classes.active_tab : classes.default_tabStyle}
                style={{ color: "black", textTransform: "capitalize" }}
                label="UPI Mandate"
              />
            </Tabs>
            {this.state.activeTabIndex === 1 && (
              <Button
                className={classes.reconButton}
                disableRipple
                onClick={this.triggerInvoiceRecon}
              >
                {reconcilingInvoices ? (
                  <CircularProgress
                    style={{
                      color: color.WHITE,
                      height: "15px",
                      width: "15px",
                    }}
                  />
                ) : (
                  "Reconcile Payments"
                )}
              </Button>
            )}
          </AppBar>
        </div>
      </Paper>
    );
  };

  checkScroll = async () => {
    const { totalItems, currentPage, totalItemToFetch } = this.state;
    const { updateSubscription } = this.props;
    const elem = document.getElementById("planList");
    if (elem.scrollHeight - Math.floor(elem.scrollTop) - elem.offsetHeight === 0) {
      if (this.allSubscriptions.length !== totalItems) {
        this.setState({ isFetchingSubscriptions: true });
        const newSusbcription = await this.fetchSubscription(currentPage + 1);
        const { subscriptions } = newSusbcription;
        if (subscriptions) {
          this.setState({ isFetchingSubscriptions: false });
          subscriptions.forEach((subscription) => {
            const { member_config: memberConfig } = subscription;
            if (subscription.version && subscription.version === 1 && memberConfig) {
              const newConstraints = {
                adult: memberConfig.adult,
              };
              if (memberConfig.hasOwnProperty("child") && memberConfig.child >= 0) {
                newConstraints.child = memberConfig.child;
              }
              subscription.product.constraint.allowed_members = newConstraints;
            }
          });
        }
        // this.allSubscriptions = this.allSubscriptions.concat(subscriptions);
        updateSubscription({
          page: { size: totalItemToFetch, number: currentPage + 1 },
          subscriptions,
        });
        this.setState((prevState) => ({
          currentPage: prevState.currentPage + 1,
        }));
      } else {
        this.setState({
          isFetchingSubscriptions: false,
        });
      }
    }
  };

  renderFirstTab = () => {
    const { classes } = this.props;
    const { isFetchingSubscriptions, totalItems } = this.state;
    if (this.props.error.subscriptionDetailsErrorCode) {
      return (
        <div
          style={{
            overflowY: "hidden",
            overflowX: "scroll",
            border: "0.5px solid #f4f4f4",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
            backgroundColor: "white",
            width: "23%",
          }}
        >
          <div style={{ marginLeft: "13vh", marginTop: "25vh" }}>
            <NoPlans />
            <Typography style={{ color: "grey", fontSize: "1.5vh", fontStyle: "italic" }}>
              Error in fetching subscriptions.
            </Typography>
          </div>
        </div>
      );
    } else if (
      this.props.familyDetails.allSubscriptions &&
      !_.isEmpty(this.props.familyDetails.allSubscriptions.subscriptions)
    ) {
      // const noPlans = _.isEmpty(this.props.familyDetails.allSubscriptions.subscriptions);
      return (
        <div id="planList" className={classes.plansListContainer} onScroll={this.checkScroll}>
          {this.props.familyDetails.allSubscriptions.subscriptions?.map((subscription, index) => {
            let selectedStyle = {
              selectedButtonColor: "white",
            };
            if (index === this.state.subscriptionIndex) {
              selectedStyle = {
                selectedButtonColor: "#f4f4f4",
              };
            }

            // let whetherPending = subscription.status === 'pending';
            const statusColor = () => {
              if (subscription.tncStatus === tncStatus.FAILED) return "#BB2E2E";
              switch (subscription.status) {
                case subscriptionStatus.DRAFT:
                  return "#CDBA3D";
                case subscriptionStatus.PENDING:
                  return "#FFA300";
                case subscriptionStatus.ONBOARDING: {
                  return "#FFA300";
                }
                case subscriptionStatus.ENDORSEMENT:
                  return "#FFA300";
                case subscriptionStatus.ACTIVE: {
                  return "green";
                }
                case subscriptionStatus.CANCELLED:
                  return "#BB2E2E";
                case subscriptionStatus.PLAN_CHANGED:
                  return "orange";
                case subscriptionStatus.PLAN_CHANGE_PENDING:
                  return "purple";
                case subscriptionStatus.WITHDRAWN:
                  return "#BB2E2E";
                default:
                  return "orange";
              }
            };

            // renamed whetherStatusNeedsToBeShown. If false, subscriptionStatus is an unexpected value
            let isSubscriptionStatusValid = Object.values(subscriptionStatus).includes(subscription.status);

            const onboardingStatus = () => {
              // all other statuses consumed as-is:
              if (isSubscriptionStatusValid) {
                if (String(subscription.status).includes('_')) {
                  return subscription.status.split("_").join(" ");
                } return subscription.status;
              }
              return "";
            };

            return (
              <Button
                disabled={
                  this.props.familyDetails.invoicesOfSubscription === null &&
                  this.state.activeTabIndex === 1
                } // Product name button is disabled only when getInvoices call is happening (i.e, invoicesOfSubscriptions === null) AND active Tab === 1 (This is to not disable the button when activeTab === 0 , services)
                style={{
                  display: "inline-block",
                  textAlign: "left",
                  backgroundColor: selectedStyle.selectedButtonColor,
                  // height: '67px',
                  cursor: "pointer",
                  textTransform: "capitalize",
                  width: "100%",
                  padding: "1rem",
                }}
                key={index}
                onClick={() => this.clickMember(index)}
              >
                {/* {this.renderProductName(index, isSubscriptionStatusValid, subscription)} */}
                <Typography className={classes.productName}>
                  {getSubscriptionProductName(subscription)}
                </Typography>
                <Typography className={classes.subscriptionId}>
                  {subscription.id}
                </Typography>
                {(subscription.version === 1 &&
                  subscription.addons?.some((addon) => addon.optional)) ||
                (subscription.version === 0 && subscription.addons) ? (
                  <Typography className={classes.optionalAddonDescription}>
                    {getOptionalAddonsDescriptionForSubscription(subscription)}
                  </Typography>
                ) : null}
                <Typography
                  className={classes.productSubscriptionStatus}
                  style={{
                    color: statusColor(),
                  }}
                >
                  {onboardingStatus()}
                </Typography>
                {subscription.status === 'active' && subscription.plan_change && subscription.plan_change.business && subscription.plan_change.product && (!subscription?.plan_change.code || (subscription?.plan_change.code && subscription?.plan_change.code === 'price_hike')) ? 'Upcoming price hike' : ''}
              </Button>
            );
          })}
          {isFetchingSubscriptions && (
            <CircularProgress
              style={{
                width: "30px",
                height: "30px",
                color: "orange",
                top: "55%",
                position: `${!totalItems ? "absolute" : "unset"}`,
              }}
              disableShrink
            />
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            overflowY: "scroll",
            overflowX: "scroll",
            border: "0.5px solid #f4f4f4",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
            backgroundColor: "white",
            width: "25%",
          }}
        >
          <div style={{ marginLeft: "13vh", marginTop: "25vh" }}>
            <NoPlans />
            <Typography
              style={{
                color: "grey",
                fontSize: "1.5vh",
                fontStyle: "italic",
                marginRight: "15vh",
              }}
            >
              No active plans
            </Typography>
          </div>
        </div>
      );
    }
  };

  handleChange = (event, value) => {
    this.setState({ activeTabIndex: value });
  };

  // currently we recon only the first 10 invoices
  // in the list of invoices
  triggerInvoiceRecon = async () => {
    const { familyDetails, auth } = this.props;
    const familyId = familyDetails.currentCustomer.family.id;
    const subscriptionInvoices = familyDetails.invoicesOfSubscription?.invoices ?? [];
    const accessToken = auth.authDetails.data.access_token;
    this.setState({
      reconcilingInvoices: true,
    });
    const allReconciliationPromises = subscriptionInvoices.map((invoice) => {
      const invoiceId = invoice.id;
      return reconOrderOrInvoiceById(accessToken, invoiceId, true);
    });

    Promise.all(allReconciliationPromises)
      .then((responses) => {
        setTimeout(() => {
          this.setState({
            reconcilingInvoices: false,
          });
          history.push({
            pathname: "/loadingFamily",
            search: `?familyId=${familyId}`,
          });
        }, 10000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renderAppBar = () => {
    const { classes } = this.props;
    const { activeTabIndex, reconcilingInvoices } = this.state;
    return (
      <Paper className={classes.root} elevation={0}>
        <div>
          <AppBar
            position="static"
            elevation={0}
            style={{
              minWidth: "35.5rem",
              display: "flex",
              flexDirection: "row",
              background: "white",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={activeTabIndex}
              onChange={this.handleChange}
              style={{
                textTransform: "capitalize",
                backgroundColor: "white",
                fontWeight: "bold",
              }}
              classes={{ indicator: classes.indicator, root: classes.tab }}
            >
              <Tab
                className={activeTabIndex === 0 ? classes.active_tab : classes.default_tabStyle}
                style={{ color: "black", textTransform: "capitalize" }}
                label="Services"
              />
              <Tab
                className={activeTabIndex === 1 ? classes.active_tab : classes.default_tabStyle}
                style={{ color: "black", textTransform: "capitalize" }}
                label="Invoices"
              />
              <Tab
                className={activeTabIndex === 2 ? classes.active_tab : classes.default_tabStyle}
                style={{ color: "black", textTransform: "capitalize" }}
                label="UPI Mandate"
              />
              {activeTabIndex === 0 && (
                <div className={`${classes.appBarColumn} ${classes.badges}`}>
                  <Button
                    className={classes.recordActivityCta}
                    onClick={() => this.handleSendCoiLinkDrawer()}
                  >
                    Send COI
                  </Button>
                </div>
              )}
            </Tabs>
            {activeTabIndex === 1 && (
              <Button
                className={classes.reconButton}
                disableRipple
                onClick={this.triggerInvoiceRecon}
              >
                {reconcilingInvoices ? (
                  <CircularProgress
                    style={{
                      color: color.WHITE,
                      height: "15px",
                      width: "15px",
                    }}
                  />
                ) : (
                  "Reconcile Payments"
                )}
              </Button>
            )}
          </AppBar>
        </div>
      </Paper>
    );
  };

  handelSendLinkDrawer = () => {
    this.setState((prevState) => ({
      ...prevState,
      openSendLinkDrawer: !prevState.openSendLinkDrawer,
    }));
  };

  handleSendCoiLinkDrawer = () => {
    this.setState((prevState) => ({
      ...prevState,
      openSendCoiLinkDrawer: !prevState.openSendCoiLinkDrawer,
    }));
  };

  copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  handleCopyExternalId = (externalId) => {
    this.copyTextToClipboard(externalId)
      .then(() => {
        this.setState({ isCopied: true });
        setTimeout(() => {
          this.setState({ isCopied: false });
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCopyUPIId = (upiID) => {
    this.copyTextToClipboard(upiID)
      .then(() => {
        this.setState({ isUpiIDCopied: true });
        setTimeout(() => {
          this.setState({ isUpiIDCopied: false });
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderThirdTab = () => {
    const { classes, familyDetails } = this.props;
    const {
      openSendLinkDrawer,
      openSendCoiLinkDrawer,
      isCopied,
      isUpiIDCopied,
      subscriptionIndex,
    } = this.state;
    const subscriptions = familyDetails.allSubscriptions.subscriptions ?? [];
    const selectedSubscription = subscriptions[subscriptionIndex];
    let availableServices = {};
    if (selectedSubscription?.version !== 1) {
      // TODO: This will run for all future subscription versions, but should run only for version 0.
      // To be fixed.
      availableServices = _.pick(subscriptions[this.state.subscriptionIndex], [
        "primary_care",
        "health_insurance",
        "accidental_insurance",
        "addons",
      ]);
      if (availableServices.addons) {
        // super topup members are derived from health insurance members;
        availableServices.addons.members = availableServices.health_insurance?.members ?? [];
      }
      if (availableServices.accidental_insurance) {
        // accidental insurance only covers the primary member;
        availableServices.accidental_insurance.members =
          availableServices.health_insurance?.members.filter(
            (member) => member.relationship === "primary"
          ) ?? [];
      }
    } else if (selectedSubscription?.version === 1) {
      selectedSubscription.addons.forEach((addon) => {
        availableServices[addon.category] = addon;
      });
    }
    const isMandateAvailable =
      selectedSubscription?.version === 1
        ? selectedSubscription.product.price <= 15000
        : selectedSubscription?.mandate;
    const isSubscriptionStatusActive =
      subscriptions[this.state.subscriptionIndex]?.status === subscriptionStatus.ACTIVE;
    // computing subscription validity in months is easier - can be converted to other units;
    const subscriptionDuration = selectedSubscription
      ? getSubscriptionDurationLabel(selectedSubscription)
      : null;
    const isLapsed = selectedSubscription
      ? moment(selectedSubscription.end_date).isBefore(moment().startOf("month"))
      : true;
    const mandateStatusColorAndInfo = () => {
      let status = ''
      if (isMandateAvailable) {
        if (subscriptions[this.state.subscriptionIndex]?.mandate) {
          status = subscriptions[this.state.subscriptionIndex]?.mandate?.status;
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.INIT) {
          return {
            color: "#FFA300",
            background: "#FFF1D9",
            border: "1px solid #FFA300",
            text: "pending",
            width: "82px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.ACTIVE) {
          return {
            color: "#0D7E51",
            background: "#DBECE5",
            border: "1px solid #0D7E51",
            text: "active",
            width: "71px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.AUTHORIZATION_FAILED) {
          return {
            color: "#BB2E2E",
            background: "#F3D9D9",
            border: "1px solid #BB2E2E",
            text: "authentication Failure",
            width: "196px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.EXPIRED) {
          return {
            color: "#808080",
            background: "#E9E9E9",
            border: "1px solid #808080",
            text: "expired",
            width: "80px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.REJECT) {
          return {
            color: "#BB2E2E",
            background: "#F3D9D9",
            border: "1px solid #BB2E2E",
            text: "reject",
            width: "82px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.CLOSED) {
          return {
            color: "#BB2E2E",
            background: "#F3D9D9",
            border: "1px solid #BB2E2E",
            text: "closed",
            width: "82px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.CONFIRMED) {
          return {
            color: "#0D7E51",
            background: "#DBECE5",
            border: "1px solid #0D7E51",
            text: "confirmed",
            width: "101px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.AUTHORIZED) {
          return {
            color: "#FFA300",
            background: "#FFF1D9",
            border: "1px solid #FFA300",
            text: "authorized",
            width: "101px",
          };
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.IN_AUTHORIZATION) {
          return {
            color: "#FFA300",
            background: "#FFF1D9",
            border: "1px solid #FFA300",
            text: "in authorization",
            width: "196px",
          };
        }
      }
    };
    const mandateStatusInfo = () => {
      let status = '';
      if (isMandateAvailable) {
        if (subscriptions[this.state.subscriptionIndex]?.mandate) {
          status = subscriptions[this.state.subscriptionIndex]?.mandate?.status;
        }
        if (status === strings.SUBSCRIPTION_MANDATE_STATUS.EXPIRED) return false;
        else if (status === strings.SUBSCRIPTION_MANDATE_STATUS.REJECT) return false;
        else if (status === strings.SUBSCRIPTION_MANDATE_STATUS.AUTHORIZATION_FAILED) return false;
        else if (status === strings.SUBSCRIPTION_MANDATE_STATUS.CLOSED) return false;
        else return true;
      } else return false;
    };
    const subscriptionMandateTooltipMsg = (isSubscriptionNotActive, mandateStatus) => {
      if (isSubscriptionNotActive)
        return strings.SUBSCRIPTION_MANDATE_TOOLTIP_MSG.SUBSCRIPTION_NOT_ACTIVE;
      else {
        switch (mandateStatus) {
          case strings.SUBSCRIPTION_MANDATE_STATUS.ACTIVE:
            return strings.SUBSCRIPTION_MANDATE_TOOLTIP_MSG.ACTIVE;
          case strings.SUBSCRIPTION_MANDATE_STATUS.AUTHORIZED:
            return strings.SUBSCRIPTION_MANDATE_TOOLTIP_MSG.AUTHORIZED;
          case strings.SUBSCRIPTION_MANDATE_STATUS.CONFIRMED:
            return strings.SUBSCRIPTION_MANDATE_TOOLTIP_MSG.CONFIRMED;
          case strings.SUBSCRIPTION_MANDATE_STATUS.INIT:
            return strings.SUBSCRIPTION_MANDATE_TOOLTIP_MSG.INIT;
          case strings.SUBSCRIPTION_MANDATE_STATUS.IN_AUTHORIZATION:
            return strings.SUBSCRIPTION_MANDATE_TOOLTIP_MSG.IN_AUTHORIZATION;
          default:
            return "";
        }
      }
    };
    if (this.state.activeTabIndex === 0) {
      return (
        <div
          style={{
            overflowY: "scroll",
            height: "1000 px",
            border: "0.5px solid #f4f4f4",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "none",
            backgroundColor: "white",
            width: "49%",
            fontFamily: "Rubik",
          }}
        >
          {this.renderAppBar()}

          <br />
          {Object.keys(availableServices)
            .filter((key) => !["chh", "primary_care", "opd"].includes(key))
            .map((service, index) => {
              return (
                <ServiceCard
                  key={index}
                  product={selectedSubscription.product}
                  // show the display name if available, else fall back to category
                  serviceName={availableServices[service]?.display_name ?? service}
                  validity={subscriptionDuration}
                  serviceDetail={availableServices[service]}
                  lapsed={isLapsed}
                  subscriptionId={selectedSubscription.id}
                  subscriptionVersion={selectedSubscription.version}
                  toggleUploadDocumentDrawer={this.handelUploadDocumentDrawer}
                />
              );
            })}
          {Object.keys(availableServices).length === 0 && (
            <div className={classes.noServicesContainer}>
              COI details will be available after customer onboarding.
            </div>
          )}
          <Drawer
            disableEnforceFocus
            anchor="right"
            open={openSendCoiLinkDrawer}
            onClose={() => this.handleSendCoiLinkDrawer()}
          >
            <Paper style={{ width: "360px", height: "100%" }}>
              <SendLinkDrawer
                drawerFor="coi"
                subscription={subscriptions[this.state.subscriptionIndex]}
                closeSendLinkDrawer={() => this.handleSendCoiLinkDrawer()}
              />
            </Paper>
          </Drawer>
        </div>
      );
    } else if (this.state.activeTabIndex === 1) {
      return (
        <div
          style={{
            overflowY: "scroll",
            height: "1000 px",
            border: "0.5px solid #f4f4f4",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "none",
            backgroundColor: "white",
            width: "49%",
            textAlign: "center",
          }}
        >
          {this.renderAppBar()}
          <br />
          {subscriptions[this.state.subscriptionIndex] ? (
            <InvoiceList subscriptionId={subscriptions[this.state.subscriptionIndex].id} />
          ) : (
            <> </>
          )}
        </div>
      );
    } else if (this.state.activeTabIndex === 2) {
      const mandateStatus = isMandateAvailable
        ? subscriptions[this.state.subscriptionIndex]?.mandate?.status
        : null;
      return (
        <>
          <Box className={classes.tabContentContainer}>
            {_.isUndefined(isMandateAvailable) && (
              <Box className={classes.noMandateImage}>
                <NoMandateIllustration />
                <Typography className={classes.mandateNotPresentText}>
                  {" "}
                  No UPI Mandate is Linked <br /> to this Plan{" "}
                </Typography>
              </Box>
            )}

            {this.renderAppBar()}
            <br />
            <Box
              style={{
                padding: "0px 24px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Mandate Card start */}
              <Typography className={classes.sectionTitle}>UPI Mandate Details</Typography>
              <Tooltip
                title={
                  mandateStatusInfo() || !isSubscriptionStatusActive ? (
                    <Alert severity="info" color="#000000" className={classes.tooltip}>
                      {subscriptionMandateTooltipMsg(!isSubscriptionStatusActive, mandateStatus)}
                    </Alert>
                  ) : (
                    ""
                  )
                }
                arrow
              >
                <span>
                  <Button
                    className={classes.sendRequestOrangeBtn}
                    disabled={
                      mandateStatusInfo() ||
                      !isSubscriptionStatusActive ||
                      (selectedSubscription &&
                        selectedSubscription.version === 1 &&
                        selectedSubscription.product.price > 15000)
                    }
                    onClick={() => this.handelSendLinkDrawer()}
                    style={{
                      opacity:
                        mandateStatusInfo() ||
                        !isSubscriptionStatusActive ||
                        (selectedSubscription &&
                          selectedSubscription.version === 1 &&
                          selectedSubscription.product.price > 15000)
                          ? 0.3
                          : 1,
                      color: "white",
                    }}
                  >
                    Send Mandate Link
                  </Button>
                </span>
              </Tooltip>
              {isMandateAvailable && (
                <Box className={classes.mandateCard}>
                  <Box className={classes.mandateCardRow}>
                    <Box className={classes.mandateCardRowItem}>
                      <Typography className={classes.mandateCardInfo}>Ext ID:&nbsp;</Typography>
                      <Typography
                        className={`${classes.mandateCardSubInfo} ${classes.showPointer}`}
                        onClick={() =>
                          this.handleCopyExternalId(
                            subscriptions[this.state.subscriptionIndex]?.mandate?.external_id
                          )
                        }
                      >
                        {subscriptions[this.state.subscriptionIndex]?.mandate?.external_id}
                      </Typography>
                      <CopyIcon
                        onClick={() =>
                          this.handleCopyExternalId(
                            subscriptions[this.state.subscriptionIndex]?.mandate?.external_id
                          )
                        }
                        className={classes.showPointer}
                      />
                      {isCopied && (
                        <Chip label="copied" className={classes.copiedChip} variant="outlined" />
                      )}
                    </Box>
                    <Box className={classes.mandateCardRowItem}>
                      <Box
                        className={classes.mandateStatusInfo}
                        style={{
                          background: mandateStatusColorAndInfo()?.background
                            ? mandateStatusColorAndInfo()?.background
                            : "",
                          border: mandateStatusColorAndInfo()?.border
                            ? mandateStatusColorAndInfo()?.border
                            : "",
                          width: mandateStatusColorAndInfo()?.width
                            ? mandateStatusColorAndInfo()?.width
                            : "",
                        }}
                      >
                        <Typography
                          className={classes.mandateStatusInfoText}
                          style={{
                            color: mandateStatusColorAndInfo()?.color
                              ? mandateStatusColorAndInfo()?.color
                              : "",
                          }}
                        >
                          {mandateStatusColorAndInfo()?.text && mandateStatusColorAndInfo()?.text}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.mandateCardRow}>
                    <Box className={classes.mandateCardRowItem}>
                      <Typography className={classes.mandateCardSubInfo}>Date:&nbsp;</Typography>
                      <Typography className={classes.date}>
                        {getISTFormat2(
                          subscriptions[this.state.subscriptionIndex]?.mandate?.start_date
                        )}
                        &nbsp;-&nbsp;
                        {getISTFormat2(
                          subscriptions[this.state.subscriptionIndex]?.mandate?.expiry_date
                        )}
                      </Typography>
                    </Box>
                    <Box className={classes.mandateCardRowItem}>
                      <Typography className={classes.mandateCardSubInfo}>
                        Mandate Amount:&nbsp;
                      </Typography>
                      <Typography className={classes.date}>
                        ₹{subscriptions[this.state.subscriptionIndex]?.mandate?.txn_amount}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider className={classes.divider} />
                  {subscriptions[this.state.subscriptionIndex]?.mandate?.vpa ? (
                    <Box className={classes.mandateCardRow}>
                      <Box className={classes.mandateCardRowItem}>
                        <Typography className={classes.mandateCardSubInfo}>
                          Max mandate amount:&nbsp;
                        </Typography>
                        <Typography className={classes.mandateAmountTextInfo}>
                          ₹{subscriptions[this.state.subscriptionIndex]?.mandate?.max_amount}
                        </Typography>
                      </Box>
                      <Box className={classes.mandateCardRowItem}>
                        <Typography className={classes.mandateCardSubInfo}>
                          UPI ID:&nbsp;
                        </Typography>
                        <Typography
                          className={`${classes.mandateCardUpiText} ${classes.showPointer}`}
                          onClick={() =>
                            this.handleCopyUPIId(
                              subscriptions[this.state.subscriptionIndex]?.mandate?.vpa
                            )
                          }
                        >
                          {subscriptions[this.state.subscriptionIndex]?.mandate?.vpa}
                        </Typography>
                        <CopyIcon
                          onClick={() =>
                            this.handleCopyUPIId(
                              subscriptions[this.state.subscriptionIndex]?.mandate?.vpa
                            )
                          }
                          className={classes.showPointer}
                        />
                        {isUpiIDCopied && (
                          <Chip label="copied" className={classes.copiedChip} variant="outlined" />
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Box className={classes.mandateCardRow}>
                      <Box className={classes.mandateCardRowItem}>
                        <Typography className={classes.mandateCardSubInfo}>
                          Max mandate amount:&nbsp;
                        </Typography>
                        <Typography className={classes.mandateAmountTextInfo}>
                          ₹{subscriptions[this.state.subscriptionIndex]?.mandate?.max_amount}
                        </Typography>
                      </Box>
                      <Box className={classes.mandateCardRowItem}>
                        <Typography className={classes.mandateCardSubInfo}>
                          UPI ID:&nbsp;
                        </Typography>
                        <Typography className={`${classes.notAvailableText}`}>
                          Not Available
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {/* Mandate Card end*/}
            </Box>
            {!isMandateAvailable && (
              <span className={classes.noMandateCaption}>
                A mandate cannot be created for this subscription, since the price is greater than{" "}
                {formatNumberAsIndianCurrency(15000)}.
              </span>
            )}
          </Box>
          <Drawer
            disableEnforceFocus
            anchor="right"
            open={openSendLinkDrawer}
            onClose={() => this.handelSendLinkDrawer()}
          >
            <Paper style={{ width: "53vh", height: "100%" }}>
              <SendLinkDrawer
                drawerFor="mandate"
                subscription={subscriptions[this.state.subscriptionIndex]}
                closeSendLinkDrawer={() => this.handelSendLinkDrawer()}
              />
            </Paper>
          </Drawer>
        </>
      );
    }
  };

  renderMiddleTabs = () => {
    const { classes } = this.props;
    const subscriptions = this.props.familyDetails.allSubscriptions
      ? this.props.familyDetails.allSubscriptions.subscriptions
      : [];
    return (
      <div className={classes.planDetailsContainer}>
        {
          subscriptions[this.state.subscriptionIndex] && (
            <>
              <Typography
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "18px",
                  padding: "30px 24px 0",
                  color: "black",
                  fontFamily: "Rubik",
                }}
              >
                Plan Details
              </Typography>
                <MiddleTab
                  subscription={subscriptions[this.state.subscriptionIndex]}
                  subscriptionMark={this.props.subscriptionMark}
                  updatedOPDDetails={this.allSubscriptions[this.state.subscriptionIndex]}
                />
            </>
          )
        }
        {
          !subscriptions[this.state.subscriptionIndex] && <>
            <div style={{ marginLeft: "13vh", marginTop: "25vh" }}>
              <NoPlans />
              <Typography
                style={{
                  color: "grey",
                  fontSize: "1.5vh",
                  fontStyle: "italic",
                  marginRight: "15vh",
                }}
              >
                No active plans
              </Typography>
          </div>
          </>
        }
      </div>
    );
  };

  render() {
    return (
      <div
        className="main"
        style={{
          marginTop: "0.25vh",
          display: "flex",
          flexFlow: "row",
          height: "calc(100vh - 27%)",
          width: "100%",
        }}
      >
        {this.renderFirstTab()}
        {this.renderMiddleTabs()}
        {this.renderThirdTab()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    familyDetails: state.familyDetails,
    error: state.error,
  };
};

const mapDispatchToProps = {
  getInvoices,
  resetInvoiceDetails,
  resetOrderDetails,
  resetOpdAmounts,
  resetOpdTransactions,
  closeViewDetails,
  getPlanBillingPrefEvent,
  updateSubscription,
  getCustomerDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Plans));
