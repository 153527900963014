import React from 'react';
import { connect } from 'react-redux';
import TicketingStatusTile from './ticketingStatusTile';

import css from './style.module.scss';
import { ticketType } from '../../../constants';
import { ticketingActions } from '../../../actions';

class TicketingStatusBar extends React.Component {

  componentDidMount() {
    const { getTicketsCountStatus, auth } = this.props;
    let partnerId;
    if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
      partnerId = auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user ? auth.authDetails.data.user.id : '';
    }
    getTicketsCountStatus(partnerId); 
    document.addEventListener('visibilitychange', () => {
      if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
        getTicketsCountStatus(partnerId);
      }
    });
  }

  render() {
    const { ticketCount } = this.props.ticketing;
    return (
      <div className={css.ticketing_statusbar_container}>
        <TicketingStatusTile ticket={ticketType.PENDING} tileText1={'Pending'} tileText2={ticketCount && ticketCount.pending ? ticketCount.pending : 0} />
        <TicketingStatusTile ticket={ticketType.RESOLVED} tileText1={'Resolved'} tileText2={ticketCount && ticketCount.pending ? ticketCount.resolved : 0}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, error: state.error, ticketing: state.ticketing };
};

const mapDispatchToProps = (dispatch) => ({
  getTicketsCountStatus: (partnerId) => {
    dispatch(ticketingActions.getTicketsCountStatusEvent(partnerId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketingStatusBar);