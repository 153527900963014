import React from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { getConsultationsByClinicId, setPresentableConsultationQueue, resetConsultationQueue, incrementConsultationQPage, decrementConsultationQPage, resetConsultationQPageNumber } from '../../actions';
import ParticularPendingC from './ParticularPendingC';
import { consultationStatus } from '../../common/constants';
import { errors } from '../../common/errors';
import moment from 'moment';


const startDateValue = moment().startOf('day').valueOf();
const endDateValue = moment().endOf('day').valueOf();

class PendingConsultations extends React.Component {

    constructor() {
        super();
        this.state = { totalPages: null, prevButtonDisabled: false, nextButtonDisabled: false, isPageDone: false, currentConsultationPage: 1 }
    }

    componentDidMount() {
        const { resetConsultationQueue, auth } = this.props;
        resetConsultationQueue();
        if (auth?.currentClinicLocation?.id) {
            this.getPendingConsultations(auth?.currentClinicLocation?.id, consultationStatus.PENDING, 1, 'desc', startDateValue, endDateValue, "updated_at")
        }
    }

    getPendingConsultations = (clinicId, consultationStatus, page, sortDir, fromDate, toDate, sortKeyProp) => {
        const { getConsultationsByClinicId } = this.props;
       getConsultationsByClinicId(clinicId, consultationStatus, page, sortDir, fromDate, toDate, sortKeyProp);
    }

    loadNext = () => {
        const { auth, consultationDetails } = this.props;
        if (consultationDetails?.consultationQueue?.total_count && (consultationDetails?.currentConsultationQPage < Math.ceil(consultationDetails?.consultationQueue?.total_count / 10))) {
            this.setState({ nextButtonDisabled: true });
            //    this.setState({ currentConsultationPage : this.state.currentConsultationPage + 1})
            setTimeout(function () {
                this.props.incrementConsultationQPage();
            }.bind(this), 200);
            setTimeout(function () {
                this.setState({ nextButtonDisabled: false });
            }.bind(this), 1000);
            setTimeout(function () {
                this.getPendingConsultations(auth?.currentClinicLocation?.id, consultationStatus.PENDING, consultationDetails.currentConsultationQPage, 'desc', startDateValue, endDateValue,  "updated_at");
            }.bind(this), 400);
        }
    }

    renderConsultations = () => {

        if (!this.props.consultationDetails.consultationQueue) {
            return (<div> <CircularProgress style={{ color: 'orange', marginLeft: '48%', marginRight: '48%', marginTop: '20vh' }} disableShrink /> </div>);
        } else if (this.props.consultationDetails.consultationQueue === errors.ERROR_IN_FETCHING_CONSULTATIONS_BY_CLINIC_ID) {
            return (<div style={{ marginLeft: '15vh' }}> <Typography style={{ fontFamily: 'Rubik' }}>Error while loading pending consultations</Typography></div>);
        } else if (this.props.consultationDetails.representableConsultationQueue && this.props.consultationDetails.representableConsultationQueue.length === 0) {
            return (<div><Typography style={{ fontStyle: 'italic', fontSize: '3vh', marginLeft: '40vh', marginTop: '10vh', color: 'grey', fontFamily: 'Rubik' }}>No Pending consultations</Typography></div>);
        } else {
            return (
                <div style={{ overflow: 'scroll', position: 'relative', height: '80vh' }} >
                    {/* this.props.consultationDetails.representableConsultations */}
                    {this.props?.consultationDetails?.representableConsultationQueue?.length > 0 && _.map(this.props.consultationDetails.representableConsultationQueue, (consultation, index) => {
                        return (<ParticularPendingC consultation={consultation} position={index + 1} />);
                    })}
                    <div style={{ marginLeft: '2vw', fontSize: '1.5vh' }}>

                         {(this.props.consultationDetails && this.props.consultationDetails.consultationQueue && this.props.consultationDetails.consultationQueue.total_count && this.props.consultationDetails.consultationQueue.total_count > 0 && (this.props.consultationDetails.currentConsultationQPage < (Math.ceil(this.props.consultationDetails.consultationQueue.total_count / 10))))
                        && (this.props.consultationDetails.currentConsultationPage !== Math.ceil(this.props.consultationDetails.consultationQueue.total_count / 10))
                           ? 
                            <Button
                                id="determining_component"
                                 ref={(el) => { this.determining_component = el }} 
                                style={{ marginLeft: '32vw', fontSize: '1.4vh', textTransform: 'capitalize' }} onClick={this.loadNext} disabled={this.state.nextButtonDisabled} >Load more </Button> 
                                  : <> </>} 
                    </div>
                </div>
            );
        }
    };


    render() {
        return (
            <div >
                <br />
                {this.renderConsultations()}
                <div>
                </div>
            </div >
        );
    }

}

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
}

export default connect(mapStateToProps, { getConsultationsByClinicId, setPresentableConsultationQueue, resetConsultationQueue, incrementConsultationQPage, decrementConsultationQPage, resetConsultationQPageNumber })(PendingConsultations);