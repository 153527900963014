import { Box, Divider, Drawer, Radio, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import css from './style.module.scss';
import Rating from '@material-ui/lab/Rating'
import TextBox from '../../../../../widgets/textBox';
import { ReactComponent as PlaystoreIcon } from '../../../../../assets/icons/playstore-icon.svg';
import { ticketingActions } from '../../../../../actions';
import { ReactComponent as GreenTick } from '../../../../../assets/icons/ticketing/tick-round-green.svg';



class FeedbackStep extends Component {

    handleSendPlaystoreLinkOnSms = () => {
        const { sendPlaystoreLinkOnSms, ticketing } = this.props;
        const phoneNumber = ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? '';
        sendPlaystoreLinkOnSms(phoneNumber);
    }

    handleSendPlaystoreLinkOnWhatsapp = () => {
        const { sendPlaystoreLinkOnWhatsapp, ticketing } = this.props;
        const phoneNumber = ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? '';
        sendPlaystoreLinkOnWhatsapp(phoneNumber);
    }

    handleDoneClick = () => {
        const { resetPlaystoreLinkStatus, toggleSendPlaystoreLinkDrawer } = this.props;
        resetPlaystoreLinkStatus();
        toggleSendPlaystoreLinkDrawer();
    }
    renderSendPlaystoreLinkDrawer = () => {
        const { showSendPlaystoreLinkDrawer, toggleSendPlaystoreLinkDrawer, ticketing } = this.props;
        return (
        <Drawer disableEnforceFocus anchor="right" open={showSendPlaystoreLinkDrawer} onClose={toggleSendPlaystoreLinkDrawer}>
          <div style={{ width: '320px', height: '100vh' }} >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p style={{ color: 'black', fontWeight: 'bold', fontSize: '16px', fontFamily: 'Manrope-Bold', marginTop: '26px', marginLeft: '16px', marginBottom: '23px' }}>
                        Send Playstore link
                        </p>
                </div>
                <Divider style={{ backgroundColor: 'grey' }} />
              {
                  !(ticketing?.sendPlaystoreLinkSmsApiStatus?.status === "success" || ticketing?.sendPlaystoreLinkWhatsappApiStatus?.status === "success")&& (
                      <>
                        <div className={css.userSelectionCard} style={{ border: '2px solid #0033CC'}}>
                            <div className={css.userSelectionCardHeader}>
                            <p className={css.userName}>{ticketing?.selectedTicket?.metadata?.consultation?.customer?.name ?? ''}</p>
                            <Radio
                                checked={true}
                                value={ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? ''}
                                color="primary"
                            />
                            </div>
                            <div className={css.userNumberContainer}>
                            <p className={css.userNumberText}>{ticketing?.selectedTicket?.metadata?.consultation?.customer?.contact_number ?? ''}</p>
                            </div>
                        </div>
                        <div className={css.btnContainer} style={{ width: 'calc(320px - 32px)'}}>
                            <div className={css.btnGroup}>
                                {
                                    (ticketing?.getCustomerPreferencesApiStatus?.preferencesNotFound 
                                    || ticketing?.getCustomerPreferencesApiStatus?.data?.whatsapp?.consent === "denied") &&
                                    <button className={css.smsBtn} style={{ width: '100%' }} onClick={this.handleSendPlaystoreLinkOnSms}>SMS</button>
                                }
                                {
                                    ticketing?.getCustomerPreferencesApiStatus?.data?.whatsapp?.consent === "granted" &&
                                    <>
                                    <button className={css.smsBtn} onClick={this.handleSendPlaystoreLinkOnSms}>SMS</button>
                                    <button className={css.whatsappBtn} onClick={this.handleSendPlaystoreLinkOnWhatsapp}>WhatsApp</button>
                                    </>
                                }
                            </div>
                        </div>
                      </>
                  )
              }
              {
                  (ticketing?.sendPlaystoreLinkSmsApiStatus?.status === 'success' || ticketing?.sendPlaystoreLinkWhatsappApiStatus?.status === 'success') && (
                    <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', flexDirection: 'column'}}>
                      <GreenTick style={{ width: '54px', height: '54px' }} />
                      <p className={css.successText}>Prescription sent successfully!</p>
                    </div>
                      <div className={css.btnContainer} style={{ width: 'calc(320px - 32px)'}}>
                              <button className={css.doneBtn} onClick={this.handleDoneClick}>Done</button>
                      </div>
                    </>
                )
              }
          </div>                    
        </Drawer>
        )
      } 
    render() {
        const { ticketing, setFeedbackRating, setFeedbackComment, toggleSendPlaystoreLinkDrawer } = this.props;
        return (
            <>
            <Box className={css.feedbackRootContainer}>
                <Box className={css.feedbackLeftContainer}>
                    <Typography className={css.titleText}>Select Patient response</Typography>
                    <Rating
                    name="simple-controlled"
                    value={ticketing.feedbackForm.rating}
                    onChange={(event, newValue) => {
                        setFeedbackRating(newValue)
                    }}
                    />
                    <Typography className={css.titleText}>Add comments</Typography>
                    <TextBox onChange={setFeedbackComment} val={ticketing.feedbackForm.comment} />
                </Box>
                <Box className={css.feedbackRightContainer}>
                    <Typography className={css.titleText}>send link (if stars ≥ 4)</Typography>
                    <button className={css.outlinedBlueBtn} disabled={ticketing.feedbackForm.rating < 4} onClick={toggleSendPlaystoreLinkDrawer}>
                        <PlaystoreIcon style={{ marginRight: '7.5px' }} />
                        send playstore link
                    </button>
                </Box>
            </Box>
            {this.renderSendPlaystoreLinkDrawer()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing, consultationDetails: state.consultationDetails };
 };
   
 const mapDispatchToProps = (dispatch) => {
   return {
     setFeedbackRating: (rating) => { dispatch(ticketingActions.setFeedbackRating(rating)) },
     setFeedbackComment: (comment) => { dispatch(ticketingActions.setFeedbackComment(comment)) },
     sendPlaystoreLinkOnSms: (phoneNumber) => { dispatch(ticketingActions.sendPlaystoreLinkThroughSmsEvent(phoneNumber)) },
     sendPlaystoreLinkOnWhatsapp: (phoneNumber) => { dispatch(ticketingActions.sendPlaystoreLinkThroughWhatsappEvent(phoneNumber)) },
     resetPlaystoreLinkStatus: () => { dispatch(ticketingActions.resetSendPlaystoreLinkApiStatus()) },
  }
 }
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackStep);