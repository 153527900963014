import React from 'react';
import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import TopBar from '../components/Home/topBar/TopBar';
import { withStyles } from "@material-ui/core/styles/";
import { resetCustomerPhoneNumber } from '../actions';
import TicketingTask from '../components/ticketingTask';

class TicketingPage extends React.Component {

    // componentDidMount() {
    //     this.props.resetCustomerPhoneNumber();
    // }

    render() {
        if (!this.props.auth.isLoggedIn) {
            return (
                <LoginPage />
            )
        } else {
            return (
                <div className='main' style={{
                    height: "100vh",
                    minWidth: '80vw',
                    backgroundColor: '#f4f4f4',
                    overflow: 'hidden',
                }}>
                    <TopBar />
                    <div style={{ display: 'flex' }}>
                        <SideMenuBar />
                        <div>
                            <div style={{ height: '89vh', width: '92vw' }}>
                               <TicketingTask/>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error };
};


export default connect(mapStateToProps, { resetCustomerPhoneNumber })(withStyles()(TicketingPage));
