import React from 'react';
import { CircularProgress } from '@material-ui/core';
import css from './style.module.scss';

function ProgressIndication() {
    return (
        <CircularProgress
            color="primary"
            className={css.circular_progress}
            disableShrink
        />
    );
};

export default ProgressIndication;