import React from 'react';
import { connect } from 'react-redux';
import {
  ticketObjective, sortType, dateType, preferredLanguageList,
} from '../../../../constants';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";

import { withStyles, createMuiTheme } from '@material-ui/core/styles';

import css from './style.module.scss';
import CheckBoxHorizontal from './checkBoxHorizontal';
import RadiosVertical from './radiosVertical';
import InputSelector from './inputSelector';

import { ticketingActions } from '../../../../actions';
import { TextField, Typography } from '@material-ui/core';

// const styles = theme => ({
//   root: {
//       flexGrow: 1,
//   },
//   element: {
//       padding: theme.spacing(2),
//   },
//   textField: {
//       color: 'orange',
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//   },

//   floatingLabelFocusStyle: {
//       color: "somecolor"
//   }
// });
const style = theme => ({
  mobileInput: {
    marginTop: '16px'
  },
  mobileInput_field: {
    width: '100%',
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'black',
      fontSize: '18px',
      fontWeight: '500'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #808080'
    }
  }
})

const materialTheme = createMuiTheme({
  palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
  },
});

class FilterTicketsCard extends React.Component {

  constructor(props) {
    super(props);
    const { ticketing } = props;
    this.state = {
      selectedSortMode: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.sortMode ? ticketing.localTicketFilterOptions.sortMode : null,
      selectedLanguage: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.selectedLanguage ? ticketing.localTicketFilterOptions.selectedLanguage : null,
      selectedTicketObjectives: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.selectedTicketObjectives ? ticketing.localTicketFilterOptions.selectedTicketObjectives : [],
      selectedDeadlineFrom: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.deadlineFrom ? ticketing.localTicketFilterOptions.deadlineFrom : null,
      selectedDeadLineTo: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.deadLineTo ? ticketing.localTicketFilterOptions.deadLineTo : null,
      selectedDateType: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.selectedDateType ? ticketing.localTicketFilterOptions.selectedDateType : dateType.TODAY,
      mobileNo: '',
    }
  }

  componentDidMount() {
    const { ticketing } = this.props;
    this.setState({
      selectedSortMode: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.sortMode ? ticketing.localTicketFilterOptions.sortMode : null,
      selectedLanguage: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.selectedLanguage ? ticketing.localTicketFilterOptions.selectedLanguage : null,
      selectedTicketObjectives: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.selectedTicketObjectives ? ticketing.localTicketFilterOptions.selectedTicketObjectives : [],
      selectedDeadlineFrom: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.deadlineFrom ? ticketing.localTicketFilterOptions.deadlineFrom : null,
      selectedDeadLineTo: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.deadLineTo ? ticketing.localTicketFilterOptions.deadLineTo : null,
      mobileNo: ticketing && ticketing.localTicketFilterOptions && ticketing.localTicketFilterOptions.mobileNo ? ticketing.localTicketFilterOptions.mobileNo : '',
    })
  }


  renderLine = (text) => {
    return (
      <p className={css.filter_tickets_bottom_paragraph_line}>
        {text}
      </p>
    )
  }

  renderEmptyLine = (text) => {
    return (
      <p className={css.filter_tickets_bottom_empty_space_1}>
        {text}
      </p>
    )
  }

  selectTicketObjective = (objective) => {
    this.setState({
      selectedTicketObjectives:

        this.state.selectedTicketObjectives.includes(objective) ?
          [...this.state.selectedTicketObjectives].filter(function (e) { return e !== objective })

          :

          [objective, ...this.state.selectedTicketObjectives]
    });
  }

  selectSortMode = (sort) => {
    this.setState({ selectedSortMode: sort })
  }

  selectLanguage = (lang) => {
    this.setState({ selectedLanguage: lang })
  }

  selectDateType = (dateType) => {
    this.setState({ selectedDateType: dateType })
  }

  changeDeadLineFromDate = (date) => {
    this.setState({ selectedDeadlineFrom: date })
  }


  changeDeadLineToDate = (date) => {
    this.setState({ selectedDeadLineTo: date })
  }
  
  handelMobileNo = (mobileNo) => {
    this.setState({ mobileNo })
  }

  clearFilterOptions = () => {
    this.setState({
      selectedSortMode: sortType.ASCENDING,
      selectedLanguage: null,
      selectedTicketObjectives: [],
      selectedDeadlineFrom: null,
      selectedDeadLineTo: null,
      selectedDateType: dateType.TODAY,
      mobileNo: ''
    });
  };

  applyUpdate = async () => {
    const { getTickets, auth, updateLocalTicketFilterOptions, toggleTicketFilterDrawer, handleFilter } = this.props;
    const { selectedTicketObjectives,
      selectedSortMode,
      selectedLanguage,
      selectedDeadlineFrom,
      selectedDeadLineTo,
      selectedDateType,
      mobileNo,
    } = this.state;
    await updateLocalTicketFilterOptions(selectedSortMode,
      selectedLanguage,
      selectedTicketObjectives,
      selectedDeadlineFrom,
      selectedDeadLineTo,
      selectedDateType,
      mobileNo);
    toggleTicketFilterDrawer();
    let partnerId;
    const fetchTickets = {
      pageNo: 1,
      totalTicketToFetch: 8
    }
    if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
      partnerId = auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user ? auth.authDetails.data.user.id : '';
    }
    if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
      await getTickets(partnerId, fetchTickets.pageNo, fetchTickets.totalTicketToFetch);
      handleFilter();
    }
  }




  // this.setState((prevState) => ({ ticketFilterDrawerStatus: !prevState.ticketFilterDrawerStatus }), () => {
  //   if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
  //     getTickets(partnerId);
  //   }
  // })

  render() {
    const { toggleTicketFilterDrawer, classes } = this.props;
    const { selectedTicketObjectives,
      selectedSortMode,
      selectedLanguage,
      selectedDeadlineFrom,
      selectedDeadLineTo,
      selectedDateType,
      mobileNo,
    } = this.state;
    return (
      <div className={css.filter_tickets}>

        <div className={css.filter_tickets_top}>
          <div className={css.filter_tickets_top_header}>
            <div className={css.filter_tickets_top_header_title}>
              filter by
            </div>
            <div className={css.filter_tickets_top_header_list}>
              <button className={css.filter_tickets_top_header_list_button}
                onClick={this.clearFilterOptions}
              >
                clear
              </button>
            </div>
          </div>
        </div>
        <div className={css.filter_tickets_bottom}>

        <div className={classes.mobileInput}>
          <TextField value={mobileNo} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }}
            inputProps={{ maxLength: 10 }} onChange={(event) => this.handelMobileNo(event.target.value)} className={classes.mobileInput_field} label="Customer's Mobile number">
          </TextField>
          { mobileNo.length>3 && mobileNo.length !== 10 && <Typography className={css.error_message}> Please enter a 10-digit number </Typography>}
        </div>
          <div className={css.filter_tickets_bottom_compartment}>

            {this.renderLine("Date")}
            <InputSelector info={'Choose any'}
              list={[dateType.TODAY, dateType.YESTERDAY, dateType.LAST_WEEK, dateType.LAST_MONTH, dateType.CUSTOM]}
              selectOption={this.selectDateType}
              selectedOption={selectedDateType} />


          </div>
          {this.state.selectedDateType === dateType.CUSTOM &&
            <div className={css.filter_tickets_bottom_compartment_3}>
              {/* <div 
               className={css.filter_tickets_bottom_compartment_3_item_left}> */}

              {/* <DateInput
               
                selectedDate={selectedDeadlineFrom}
                changeDate={this.changeDeadLineFromDate}
              /> */}


<MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <div className={css.filter_tickets_bottom_compartment_3_left} >
                                <DatePicker     
                                //  variant="inline"
                                  // className={css.date_input}   
                                  
                                    value={selectedDeadlineFrom}
                                    inputVariant="outlined"
                                    onChange={this.changeDeadLineFromDate}
                                    format="dd-MM-yyyy" 
                                    // shouldDisableDate={disableNextDates(new Date())}
                                    inputProps={{style: { height: 2}}} 
                                />
                                </div>
                           <p className={css.filter_tickets_bottom_compartment_3_text}>To</p>
                           <div className={css.filter_tickets_bottom_compartment_3_right} >
                                <DatePicker  
                                //  variant="inline"
                                
                                inputVariant="outlined"     
                                    value={selectedDeadLineTo}
                                    onChange={this.changeDeadLineToDate}
                                    format="dd-MM-yyyy" 
                                    inputProps={{style: { height: 2 }}} 
                                    // shouldDisableDate={disableNextDates(new Date())}
                                />
                                </div>
                                </ThemeProvider>
                        </MuiPickersUtilsProvider> 

                        

              {/* </div> */}
              {/* <p className={css.filter_tickets_bottom_compartment_3_text}>To</p> */}

              {/* <div 
               className={css.filter_tickets_bottom_compartment_3_item_left}>
              {/* <DateInput
            
                selectedDate={selectedDeadLineTo}
                changeDate={this.changeDeadLineToDate}
              /> */}
 {/* </div> */} 
            </div>
          }
          {/* 
          <DatePicker
            variant="inline"
            label="Basic example"
            // value={selectedDate}
            // onChange={handleDateChange}
          /> */}

          {/* <DatePicker
            variant="inline"
            label="Basic example"
            // value={selectedDate}
            // onChange={handleDateChange}
          /> */}
          {/* <div className={css.filter_tickets_bottom_compartment_2}>
            <DateInput className={css.filter_tickets_bottom_compartment_2_item} />
            to
            <DateInput className={css.filter_tickets_bottom_compartment_2_item} />
          </div> */}


          {/* <div className={css.filter_tickets_bottom_divider} /> */}
          <div className={css.filter_tickets_bottom_compartment}>

            {this.renderLine("Type")}
            <CheckBoxHorizontal
              options={[
                { key: 'Onboard', value: ticketObjective.ONBOARD_CUSTOMER },
                { key: 'FollowUp', value: ticketObjective.PATIENT_FOLLOW_UP },
                { key: 'Feedback', value: ticketObjective.PATIENT_FEEDBACK },
              ]}
              selectedOptions={selectedTicketObjectives}
              selectOption={this.selectTicketObjective}
            />


          </div>

          <div className={css.filter_tickets_bottom_compartment}>

            {this.renderLine("Language")}
            <InputSelector
              info={'Choose language'} list={preferredLanguageList}
              selectOption={this.selectLanguage}
              selectedOption={selectedLanguage}
            />


          </div>

          <div className={css.filter_tickets_divider} />


          <div className={css.filter_tickets_bottom_compartment}>

            {this.renderLine("SORT BY")}
            <RadiosVertical
              options={[{ key: 'Deadline: Newest on top', value: sortType.DESCENDING }, { key: 'Deadline: Oldest on top', value: sortType.ASCENDING }]}
              selectedOption={selectedSortMode}
              selectOption={this.selectSortMode}

            />


          </div>
          <div className={css.filter_tickets_divider} />
          <div className={css.filter_tickets_bottom_compartment_2}>

            <button className={css.filter_tickets_bottom_compartment_2_button_1} onClick={toggleTicketFilterDrawer} >Cancel </button>
            <button className={css.filter_tickets_bottom_compartment_2_button_2} onClick={this.applyUpdate}
            >
              Apply
                  </button>


          </div>

        </div>



      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLocalTicketFilterOptions: (sortMode, selectedLanguage, ticketObjective, deadlineFrom, deadLineTo, selectedDateType, mobileNo) => { dispatch(ticketingActions.updateLocalTicketFilterOptionsEvent(sortMode, selectedLanguage, ticketObjective, deadlineFrom, deadLineTo, selectedDateType, mobileNo)) },
    // clearLocalTicketFilterOptions: () => { dispatch(ticketingActions.clearLocalTicketFilterOptionsEvent()) },
    getTickets: (partnerId, pageNo, pageSize) => {
      dispatch(ticketingActions.getTicketsEvent(partnerId, pageNo, pageSize));
    },
  }
}

export default withStyles(style, {withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(FilterTicketsCard));