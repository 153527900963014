import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

class ConfirmationRadio extends React.Component {

  render() {
    const { affirmative, set, reset } = this.props;
    return (
      <div className={css.box}>
        <div className= {css.container} onClick={set} style={{border: affirmative ? '2px solid #0033cc' : ''}}>
          <input type="radio" id="yes" name={"yes"} checked={affirmative} />
          <label className={affirmative ? css.container_label_active : css.container_label_inactive}  style={{fontWeight: affirmative ? 'bold' : ''}}>Yes</label>
        </div>

        <div className= {css.container}  onClick={reset} style={{border: !affirmative ? '2px solid #0033cc' : ''}}>
          <input type="radio" id="yes" name={"no"} checked={!affirmative} />
          <label className={!affirmative ? css.container_label_active : css.container_label_inactive} style={{fontWeight: !affirmative ? 'bold' : ''}}>No</label>
        </div>
      </div>
    )
  }
}

export default connect(null, null)(ConfirmationRadio);