import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import css from './style.module.scss';
import { preferredLanguageList } from '../../../../../constants';
import InputSelector from '../../../../../widgets/inputSelector';
import TextBox from '../../../../../widgets/textBox';
import { connectTeleCall, resetConnectTeleCall } from '../../../../../actions/index';
import { ticketingActions, getAllDoctors, consultationActions } from '../../../../../actions';

class BookConsultation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.props.resetConnectTeleCall();
    this.props.getAllDoctors(true);
    this.props.resetAddComment();
  }

  getAllDoctorsList = () => {
    const doctorList = [];
    const doctorListFromApi = this.props.consultationDetails && this.props.consultationDetails.allDoctors
      ? this.props.consultationDetails.allDoctors.doctors : [];
    _.forEach(doctorListFromApi, (doctorDetail) => {
      doctorList.push({ value: doctorDetail.id, label: doctorDetail.last_name ? doctorDetail.first_name + ' ' + doctorDetail.last_name : doctorDetail.first_name })
    })
    return doctorList;
  }

  bookConsultation = () => {
    const {
      selectedDoctor,
      selectedLanguage,
      updateLocalSummary,
      selectLanguage,
      selectDoctor,
      summary,
    } = this.props;
    return (
      <div className={css.book_consultation}>
        <div className={css.book_consultation_left}>


          <div className={css.book_consultation_left_header_1}>
            SUMMARY
      </div>
          <TextBox onChange={updateLocalSummary} val={summary} />

        </div>

        <div className={css.book_consultation_right}>

          <div className={css.book_consultation_right_up}>

            <div className={css.book_consultation_right_up_part_1}>

              <div className={css.book_consultation_right_up_header_1}>
                Language
      </div>
              <InputSelector info={'Select only one'}
                // style={{height: '30px'}}
                list={preferredLanguageList}
                selectOption={selectLanguage}
                selectedOption={selectedLanguage}
              />
              {/* <div className={css.book_consultation_right_up_header_1}>
                date
      </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme}>
                  <div className={css.dateField1} >
                    <DatePicker
                      //  variant="inline"
                      // className={css.date_input}   
                      shouldDisableDate={disablePreviousDates(new Date())}
                      value={consultationTime}
                      inputVariant="outlined"
                      onChange={changeConsultationTime}
                      format="dd-MM-yyyy"
                      inputProps={{ style: { height: 0.5, width: 140, fontFamily: 'Manrope-Medium', color: '#000000' } }}
                    />
                  </div>
                </ThemeProvider>
              </MuiPickersUtilsProvider> */}
            </div>


            <div className={css.book_consultation_right_up_part_2}>

            </div>

          </div>
          <div className={css.book_consultation_right_down}>
            <div className={css.book_consultation_right_down_header_1}>
              DOCTOR
      </div>
            <InputSelector info={'Select only one'}
              specialType={"doctor"}
              // style={{height: '30px'}}
              list={!_.isEmpty(this.getAllDoctorsList()) ? this.getAllDoctorsList() : []}
              selectOption={selectDoctor}
              selectedOption={selectedDoctor}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.bookConsultation()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing, consultationDetails: state.consultationDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectTeleCall: (partnerId, phoneNumber) => { dispatch(connectTeleCall(partnerId, phoneNumber)) },
    resetConnectTeleCall: () => { dispatch(resetConnectTeleCall()) },
    getAllDoctors: () => { dispatch(getAllDoctors()) },
    addCommentsToTicket: (ticketId, reason, comment) => { dispatch(ticketingActions.addCommentsToTicketEvent(ticketId, reason, comment)) },
    extendTicketDeadLine: (ticketId, deadLineTime) => { dispatch(ticketingActions.extendTicketDeadLineEvent(ticketId, deadLineTime)) },
    resetAddComment: () => { dispatch(ticketingActions.resetAddCommentEvent()) },
    bookConsultation: (consultationObject) => { dispatch(consultationActions.bookConsultationEvent(consultationObject)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookConsultation);