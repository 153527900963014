import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core'


// import { ReactComponent as ClockOrange } from '../../../../assets/icons/ticketing/clock-orange.svg';
// import { ReactComponent as TickRoundGreen } from '../../../../assets/icons/ticketing/tick-round-green.svg';

import css from './style.module.scss';
import TicketCard from '../ticketCard';
import { ticketingActions } from '../../../../actions';
import ProgressIndicator from '../../../../widgets/progressIndicator';
import FilterTicketsCard from '../filterTicketsCard';
import { getTickets } from '../../../../clients';

class TicketDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      currentPage: 1,
      totalItemToFetch: 8,
      totalItems: 0,
      partnerId: null,
      isFetchingTickets: true
    }
  }

  allTickets = [];

  async componentDidMount() {
    const { auth } = this.props;
    const { totalItemToFetch, currentPage } = this.state;
    let partnerId;
    if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
      partnerId = auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user ? auth.authDetails.data.user.id : '';
    }
    const newTickets = await this.fetchTicketing(partnerId, currentPage );
    this.allTickets = newTickets.tickets; 
    this.setState({
      partnerId,
      totalItems: newTickets.total_count,
      isFetchingTickets: newTickets.total_count <= totalItemToFetch ? false : true
    });
    // document.addEventListener('visibilitychange', () => {
    //   if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
    //     getTickets(partnerId);
    //   }
    // });
  }

  fetchTicketing = async (partnerId, currentPage) => {
      const { totalItemToFetch } = this.state;
      const { auth } = this.props;
      const { ticketing } = this.props;
      const ACCESS_TOKEN = auth.authDetails.data.access_token;
      const localTicketFilterOptions = ticketing.localTicketFilterOptions;
      const {

        deadlineFrom,
        deadLineTo,
        selectedDateType,
        selectedLanguage,
        selectedTicketObjectives,
        sortMode,
        mobileNo,
    } = localTicketFilterOptions;
    const response = await getTickets(partnerId, ACCESS_TOKEN,
      deadlineFrom,
      deadLineTo,
      selectedDateType,
      selectedLanguage,
      selectedTicketObjectives,
      currentPage,
      totalItemToFetch,
      sortMode,
      mobileNo);
    return response.data;
  }

  checkScroll = async () => {
    const { partnerId, totalItems, currentPage } = this.state;
    const elem = document.getElementById('list');
    if (elem.scrollHeight - Math.round(elem.scrollTop) - elem.clientHeight < 10) {
        if (this.allTickets.length !== totalItems) {
            const newTickets = await this.fetchTicketing(partnerId, currentPage + 1);
            const { tickets } = newTickets;
            if (tickets[tickets.length-1].id !== this.allTickets[this.allTickets.length-1].id) {
              this.allTickets = this.allTickets.concat(tickets);
              this.setState((prevState) => ({
                currentPage: prevState.currentPage + 1,
                partnerId,
              }));
            }
      } else {
        this.setState({
          isFetchingTickets: false
        })
      }
    }
  }

  handelFilter = async () => {
    const { partnerId, totalItemToFetch } = this.state;
    const pageNo = 1;
    const newTickets = await this.fetchTicketing(partnerId, pageNo);
    this.allTickets = newTickets.tickets;
    this.setState ({
      partnerId,
      currentPage: 1,
      totalItems: newTickets.total_count,
      isFetchingTickets: newTickets.total_count <= totalItemToFetch ? false : true
    });
  }

  render() {
    const { ticketing, ticketFilterDrawerStatus, toggleTicketFilterDrawer } = this.props;
    const { isFetchingTickets } = this.state;
    return (
        <div className={css.ticket_list} id="list" onScroll={this.checkScroll}>
          {ticketFilterDrawerStatus && <>
          <div className={css.overlay_box} onClick={toggleTicketFilterDrawer}/>
          <FilterTicketsCard handleFilter={this.handelFilter} toggleTicketFilterDrawer={toggleTicketFilterDrawer}/>
        </>}
        {((ticketing && ticketing.ticketListLoading) || ticketFilterDrawerStatus)  ?
          <ProgressIndicator /> :
          <> 
          {this.allTickets && this.allTickets.length ?
            this.allTickets.map((ticket) => <TicketCard ticket={ticket} key={ticket.id} />) :
            <p className={css.ticket_list_text_1}>No tickets found !</p>}
          </>}
          {isFetchingTickets && 
          <CircularProgress style={{
              color: 'orange',
          }} disableShrink />}
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, error: state.error, ticketing: state.ticketing };
};

const mapDispatchToProps = (dispatch) => ({
  selectCurrentTicket: (ticket) => {
    dispatch(ticketingActions.selectCurrentTicketEvent(ticket));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);