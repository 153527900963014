import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';


// import { ReactComponent as ClockOrange } from '../../../../assets/icons/ticketing/clock-orange.svg';
// import { ReactComponent as TickRoundGreen } from '../../../../assets/icons/ticketing/tick-round-green.svg';

import css from './style.module.scss';
import { ReactComponent as PortraiGrey } from '../../../../assets/icons/ticketing/portrait-grey.svg';
import { ReactComponent as CommentsGrey } from '../../../../assets/icons/ticketing/comments-grey.svg';

import MiniInfoTileBorderLess from '../miniInfoTileBorderLess';
import { getISTFormat7 } from '../../../../common/utils';
import ProgressIndicator from '../../../../widgets/progressIndicator';

class MedicalDetailsCard extends React.Component {

  renderLine = (text) => {
    return (
      <p className={css.medical_details_card_bottom_paragraph_line}>
        {text}
      </p>
    )
  }

  renderMedicalDetailsCard = () => {
    const { ticketing } = this.props;

    return (
     
<>
        <div className={css.medical_details_card_top}>
          <div className={css.medical_details_card_top_header}>
            <div className={css.medical_details_card_top_header_title}>
              Medical Details
            </div>
            <div className={css.medical_details_card_top_header_list}>
              {`Dr. ${ticketing && ticketing.selectedTicket && ticketing.selectedTicket.metadata && ticketing.selectedTicket.metadata.consultation && ticketing.selectedTicket.metadata.consultation.doctor ? ticketing.selectedTicket.metadata.consultation.doctor.name : '-'}
               • ${ticketing && ticketing.selectedTicket && ticketing.selectedTicket.metadata && ticketing.selectedTicket.metadata.consultation && ticketing.selectedTicket.metadata.consultation.completed_at ? getISTFormat7(ticketing.selectedTicket.metadata.consultation.completed_at) : '-'}`}
            </div>
          </div>
        </div>
        <div className={css.medical_details_card_divider} />
        <div className={css.medical_details_card_bottom}>
          <div className={css.medical_details_card_bottom_left}>
            <MiniInfoTileBorderLess text={"Issue Summary"} image={<PortraiGrey />} />
            {this.renderLine(ticketing && ticketing.selectedTicket && ticketing.selectedTicket.metadata ? ticketing.selectedTicket.metadata.consultation.summary : '')}
          </div>
          <div className={css.medical_details_card_bottom_right}>
            <MiniInfoTileBorderLess text={"Comments"} image={<CommentsGrey />} />
            {ticketing && ticketing.selectedTicket && ticketing.selectedTicket && ticketing.selectedTicket.metadata && ticketing.selectedTicket.metadata.consultation && ticketing.selectedTicket.metadata.consultation.prescription && ticketing.selectedTicket.metadata.consultation.prescription.follow_up && ticketing.selectedTicket.metadata.consultation.prescription.follow_up.questions && !_.isEmpty(ticketing.selectedTicket.metadata.consultation.prescription.follow_up.questions) ?

              ticketing.selectedTicket.metadata.consultation.prescription.follow_up.questions.map((question) =>
                <React.Fragment key={question}>{this.renderLine(question)}</React.Fragment>
              )

              :

              <> </>
            }


          </div>
        </div>
        </>
    )
  }

  render() {
    const { ticketing } = this.props;
    return ( <div className={css.medical_details_card}>
      {ticketing && ticketing.selectedTicket && ticketing.selectedTicket.id && !ticketing.ticketListLoading ?
        <>
          {this.renderMedicalDetailsCard()}
        </>
        :
        <>
          {ticketing && ticketing.ticketListLoading && <ProgressIndicator />}
        </>
      }
    </div>)
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing };
};

export default connect(mapStateToProps, null)(MedicalDetailsCard);