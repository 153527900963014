import React from 'react';
import { connect } from 'react-redux';


// import { ReactComponent as ClockOrange } from '../../../../assets/icons/ticketing/clock-orange.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { sortType, dateType } from '../../../../constants';

import css from './style.module.scss';

class TicketDetails extends React.Component {

  render() {
    const { toggleTicketFilterDrawer, ticketing } = this.props;
    const { localTicketFilterOptions } = ticketing;
    let filterCount = 0;

    if (localTicketFilterOptions.sortMode === sortType.DESCENDING) {
      filterCount++;
    }

    if (localTicketFilterOptions.selectedDateType && localTicketFilterOptions.selectedDateType !== dateType.YESTERDAY) {
      filterCount++;
    }

    if (localTicketFilterOptions.selectedLanguage) {
      filterCount++;
    }

    if (localTicketFilterOptions.selectedTicketObjectives && localTicketFilterOptions.selectedTicketObjectives.length === 1) {
      filterCount++;
    }

    return (
      <div className={css.ticket_list_header}>
          <span className={css.ticket_list_header_text}>{localTicketFilterOptions.selectedDateType ? (localTicketFilterOptions.selectedDateType.replace('_', ' ')) : 'Today'}</span>
          <div className={css.ticket_list_header_line}></div> 
          <span className={css.ticket_list_header_filter_box} onClick={toggleTicketFilterDrawer}> <FilterIcon /> </span>
          {filterCount ? <div className={css.ticket_list_header_filter_box_count} >
            <div className={css.ticket_list_header_filter_box_count_text}>
              {filterCount ? filterCount : ''}
            </div>
          </div>
          : <> </>
          }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing };
};

export default connect(mapStateToProps, null)(TicketDetails);