import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import css from './style.module.scss';
import { ReactComponent as EmptyEllipse } from '../../../../assets/icons/ticketing/empty-ellipse.svg';
import { ReactComponent as BusinessSuitcase } from '../../../../assets/icons/ticketing/business-suitcase.svg';
import { ReactComponent as LanguageKannadaIcon } from '../../../../assets/icons/ticketing/language-kannada-icon.svg';
import { ticketingActions } from '../../../../actions'

import MiniInfoTile from './miniInfoTile';
import { ticketObjective } from '../../../../constants';

class TicketCard extends React.Component {

  render() {
    const { ticket, ticketing, selectCurrentTicket } = this.props;
    const getTicketType = () => {
      if (ticket?.objective === ticketObjective.PATIENT_FOLLOW_UP) {
        return { ticketHeader: 'Follow Up', ticketColorIndicator: '#FFA300' }
      }
      if (ticket?.objective === ticketObjective.PATIENT_FEEDBACK) {
        return { ticketHeader: 'Feedback call', ticketColorIndicator: '#FF3333' }
      }      
    }

    return (
      <div className={css.ticket_card} onClick={() => selectCurrentTicket(ticket)}>
        <span className={css.ticket_card_type_indicator_ticket_type_2} 
         style={{ backgroundColor: getTicketType()?.ticketColorIndicator}} />
        <div className={css.ticket_card_top}>
          <div className={css.ticket_card_top_header}>
            <div className={css.ticket_card_top_header_title}>
              {getTicketType()?.ticketHeader} (<span className={css.ticket_card_consultation_type}>{ticket?.metadata?.consultation?.type}</span>)
            </div>
            <div className={css.ticket_card_top_header_list}>
              <div className={css.ticket_card_top_header_list_icon}>
                <EmptyEllipse />
              </div>
              <div className={css.ticket_card_top_header_list_icon}>
                <EmptyEllipse />
              </div>
              <div className={css.ticket_card_top_header_list_icon}>
                <EmptyEllipse />
              </div>
            </div>
          </div>

          <div className={css.ticket_card_top_description} >
            {`Operations > ${ticketing && ticketing.selectedTicket && ticketing.selectedTicket.assignee && ticketing.selectedTicket.assignee.first_name ? ticketing.selectedTicket.assignee.first_name : ''}`}
          </div>

          <div className={css.ticket_card_top_content} >
            <MiniInfoTile image={<LanguageKannadaIcon />} text={ ticket && ticket.lop ? ticket.lop : '-'} />

          </div>

        </div>
        <div className={css.ticket_card_divider} />

        {ticket && ticketing && ticketing.selectedTicket && ticketing.selectedTicket.id && (ticketing.selectedTicket.id === ticket.id) &&
          <div className={css.ticket_card_bottom}>
            <div className={css.ticket_card_bottom_header}>
              <div className={css.ticket_card_bottom_header_title}>
                {`Patient Name`}
              </div>
              <div className={css.ticket_card_bottom_header_rmn}>
                {`RMN: ${ticket.metadata && ticket.metadata.consultation && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer.contact_number ? ticket.metadata.consultation.customer.contact_number : '-'}`}
              </div>

            </div>
            <div className={css.ticket_card_bottom_name}>
              {`${ticket.metadata && ticket.metadata.consultation && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer.name ? ticket.metadata.consultation.customer.name : '-'}`}
            </div>
            <div className={css.ticket_card_bottom_description}>
              {ticket.metadata && ticket.metadata.consultation && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer.family && ticket.metadata.consultation.customer.family.members && !_.isEmpty(ticket.metadata.consultation.customer.family.members) ? ` ( ${ticket.metadata.consultation.customer.family.members.filter((member) => member.id === ticket.metadata.consultation.customer.id)[0].relationship} ) ` : '-'}

              {ticket.metadata && ticket.metadata.consultation && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer.family && ticket.metadata.consultation.customer.family.members && !_.isEmpty(ticket.metadata.consultation.customer.family.members) ? `${ticket.metadata.consultation.customer.family.members.filter((member) => member.relationship === 'self')[0].first_name} ${ticket.metadata.consultation.customer.family.members.filter((member) => member.relationship === 'self')[0].last_name ? ticket.metadata.consultation.customer.family.members.filter((member) => member.relationship === 'self')[0].last_name : ''}` : '-'}
            </div>
            <div className={css.ticket_card_bottom_content} >
              {ticket.metadata && ticket.metadata.consultation && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer && ticket.metadata.consultation.customer.family && ticket.metadata.consultation.customer.family.members && !_.isEmpty(ticket.metadata.consultation.customer.family.members) && ticket.metadata.consultation.customer.family.members.filter((member) => member.id === ticket.metadata.consultation.customer.id)[0].businesses ? ticket.metadata.consultation.customer.family.members.filter((member) => member.id === ticket.metadata.consultation.customer.id)[0].businesses.map((business) => <MiniInfoTile key={business.id} image={<BusinessSuitcase />} text={business.name} />) : <> - </>}
            </div>
          </div>}


      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing };
};

const mapDispatchToProps = (dispatch) => ({
  getTickets: (partnerId) => {
    dispatch(ticketingActions.getTicketsEvent(partnerId));
  },
  selectCurrentTicket: (ticket) => {
    dispatch(ticketingActions.selectCurrentTicketEvent(ticket));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketCard);