import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';
import ConfirmationRadios from '../../confirmationRadios';
import { ticketingActions } from '../../../../../actions';

class CallRecieval extends React.Component {

  componentDidMount() {
    this.props.resetAddComment();
  }

  renderLine = (text) => {
    return (
      <p className={css.follow_up_details_bottom_paragraph_line}>
        {text}
      </p>
    )
  }

  renderCallRecieval = () => {
    const { callRecieved, setCallRecievedState, resetCallRecievedState } = this.props;
    return (
      <div className={css.follow_up_details_bottom_compartment_2}>
        <div className={css.line}>
          {this.renderLine("Did patient receive the call? ")}
          <ConfirmationRadios affirmative={callRecieved} set={setCallRecievedState} reset={resetCallRecievedState} />
        </div>
        <div className={css.follow_up_details_bottom_compartment_2_right}>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderCallRecieval()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing, consultationDetails: state.consultationDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetAddComment: () => { dispatch(ticketingActions.resetAddCommentEvent()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallRecieval);