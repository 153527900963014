import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

class InputSelector extends React.Component {

  handleChange = (event) => {
    const { selectOption } = this.props;
    selectOption(event.target.value);
  }

  render() {
    const { info, list, selectedOption } = this.props;
    return (
      <div className={css.container}>
        <form>
        <select className={css.container_selector}
          onChange={this.handleChange}>
          <option className={css.container_selector_option} value={selectedOption}>{selectedOption ? selectedOption.replace('_', ' ') : info}</option>
          {list &&
            list.map((item) => <option
              value={item} key={item}>{item.replace('_', ' ')}</option>)
          }
        </select>
        </form>
      </div>
    )
  }
}

export default connect(null, null)(InputSelector);