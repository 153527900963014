import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { Typography, Button, Divider, Paper, Drawer, TextField, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { consultationStatus, consultationType } from '../../common/constants';
import { ReactComponent as BookingConfirmed } from '../../images/BookingConfirmed.svg';
import AddVitalsForm from '../../components/CustomerDetailsTask/BenefitCards/Tab3/AddVitalsForm';
import { errors } from '../../common/errors';
import { getISTTimeFormat, getAgeUnit } from '../../common/utils';
import { HEADQUARTERS_ID, accessRole, apiResponse } from '../../common/constants';
import { ReactComponent as Location } from '../../images/Location.svg';
import { ReactComponent as BookingFailed } from '../../images/BookingFailed.svg';

import { resetConsultationById, resetAddVitalsStatus, cancelConsultationById, getConsultationsByClinicId, setPresentableConsultationQueue, getConsultationsByClinicIdFirstPage, resetConsultationQPageNumber, resetCancelConsultationStatus, getAllDoctors, resetConsultationDetail, resetDoctorByConsultationId, reAssignDoctorByConsultationId } from '../../actions';
import moment from 'moment';
class ParticularUpcomingC extends React.Component {

    componentDidMount() {
        this.props.resetAddVitalsStatus();
        this.props.resetConsultationDetail();
        this.props.resetDoctorByConsultationId();
    };

    constructor(props) {
        super(props);
        this.state = {
            doctor: '',
            drawer: false,
            reAssignDrawer: false,
            // cancel consultation drawer contents;
            drawerType: '',
            mousePonterInside: false,
            numberOfCharacters: 42,
            // re-assign doctor contents;
            reAssignDrawerContent: 'init'
        };
    }

    componentDidUpdate(prevProps) {
        const { consultationDetails } = this.props;
        const { consultationDetails: oldConsultationDetails } = prevProps;
        if (consultationDetails !== oldConsultationDetails
            && consultationDetails.assignDoctorStatus !== oldConsultationDetails.assignDoctorStatus) {
                this.setState({
                    reAssignDrawerContent: 'reAssignDoctorApiCalled',
                })
                if (consultationDetails.assignDoctorStatus !== errors.ERROR_WHILE_ASSIGNING_DOCTOR) {
                    this.closeAssignDoctorDrawer(true);
                }
            }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    getUpcomingConsultationsFirstPage = () => {
        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();
        if (this.props.auth && this.props.auth.currentClinicLocation && this.props.auth.currentClinicLocation.id) {
            const consultationQueuePromise = this.props.getConsultationsByClinicIdFirstPage(this.props.auth.currentClinicLocation.id, consultationStatus.ASSIGNED, 1, 'asc', startDate, endDate);
            consultationQueuePromise.then(() => {
                if (this.props.consultationDetails && this.props.consultationDetails.consultationQueue.consultations) {
                    this.props.setPresentableConsultationQueue(this.props.consultationDetails.consultationQueue.consultations)
                }
            })
        }
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
        this.props.resetConsultationById();
        this.props.resetCancelConsultationStatus();
    };

    openVitalsDrawer = () => {
        this.setState({ vitalsDrawer: true });
    }

    closeVitalsDrawer = () => {
        this.setState({ drawer: false });
        this.props.resetConsultationQPageNumber();
        this.props.resetConsultationById();
        this.props.resetAddVitalsStatus();
        this.props.resetCancelConsultationStatus();
        this.handleClose();
    }

    closeAssignDoctorDrawer = (autoCloseFlag) => {
        const {
            resetDoctorByConsultationId, resetConsultationQPageNumber,
            resetConsultationById,
        } = this.props;
        setTimeout(() => {
            this.setState({
                reAssignDrawerContent: 'init',
                reAssignDrawer: false,
            }, () => {
                resetDoctorByConsultationId();
                resetConsultationQPageNumber();
                resetConsultationById();
            });
        }, autoCloseFlag === true ? 2400 : 0);
    }

    openCancelConsultationDrawer = () => {
        this.setState({ drawerType: 'cancelConsultation', drawer: true })
    }

    pressYesForCancel = () => {
        this.setState({ drawerType: 'loading' }, () => {
            this.cancelConsultation();
        });
    };

    pressNoForCancel = () => {
        this.setState({ drawer: false });
    };

    pressEnterVitals = () => {
        this.setState({ drawer: true, drawerType: 'enterVitals' });
    }

    pressReAssignDoctor = () => {
        this.props.getAllDoctors(true);
        this.setState({ reAssignDrawer: true });
    }

    clickConfirmReAssignDoctor = () => {
        this.setState({ reAssignDrawerContent: 'confirmation' })
    }

    clickYesForDoctorReAssignment = () => {
        const { reAssignDoctorByConsultationId, consultation } = this.props;
        const { doctor } = this.state;
        this.setState({
            reAssignDrawerContent: 'loading'
        }, () => {
            reAssignDoctorByConsultationId(consultation.id, doctor);
        })
    }

    clickTryAgain = () => {
        this.resetConsultationDetails();
        this.props.resetDoctorByConsultationId();
    }

    cancelConsultation = () => {
        const cancelConsultationPromise = this.props.cancelConsultationById(this.props.consultation.id);
        cancelConsultationPromise.then(() => {
            if (this.props.consultationDetails.cancelConsultationStatus === 204) {
                this.setState({ drawerType: 'consultationCancelled', drawer: false });
            }
        })
    }

    renderDrawerContent = () => {
        const { consultationDetails } = this.props;
        if (this.state.drawerType === 'cancelConsultation') {
            return (
                <div>
                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginLeft: '3vw', marginBottom: '0.6rem', marginTop: '3vh' }}>Cancel Consultation</Typography>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '80%', marginLeft: '35%', marginBottom: '3rem' }}> Are you sure ?</Typography>
                    <div style={{ marginBottom: '30%', marginLeft: '23%', marginRight: '20%' }}>
                        <Button onClick={this.pressNoForCancel} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '40%' }}>No</Button>
                        <Button onClick={this.pressYesForCancel} variant="filled" style={{ marginLeft: '15%', color: 'white', borderColor: 'orange', backgroundColor: 'orange', width: '40%' }} >Yes</Button>
                    </div>
                </div>
            );
        }
        if (this.state.drawerType === 'loading') {
            if (this.props.consultationDetails.cancelConsultationStatus === errors.ERROR_IN_CANCELLING_CONSULTATION) {
                return (
                    <div>
                        <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginLeft: '3vw', marginBottom: '0.6rem', marginTop: '3vh' }}>Cancel Consultation</Typography>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'Rubik', marginTop: '80%', textAlign: 'center' }}> Something went wrong !</Typography>
                    </div>
                )
            } else {
                return (
                    <div> <CircularProgress style={{ color: 'orange', marginLeft: '15rem', marginTop: '23.5rem', marginBottom: '23rem' }} disableShrink /> </div>
                );
            }

        }
        if (this.state.drawerType === 'consultationCancelled') {
            return (
                <div style={{ marginTop: '90%', marginBottom: '30%' }}>
                    <BookingConfirmed style={{ marginLeft: '45%' }} />
                    <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '30%' }}> Consultation Cancelled</Typography>
                </div>
            )
        }
        if (this.state.drawerType === 'enterVitals') {
            return (
                <AddVitalsForm
                    consultationId={this.props.consultation.id}
                    queue={consultationDetails.representableConsultationQueue}
                    closeDrawer={this.closeVitalsDrawer}
                />
            )
        }
    }

    resetConsultationDetails = () => {
        this.setState({ reAssignDrawerContent: 'details_need_to_be_entered' });
        this.props.resetConsultationDetail();
    };


    getAllDoctorsList = () => {
        const doctorList = [];
        const doctorListFromApi = this.props.consultationDetails && this.props.consultationDetails.allDoctors
            ? this.props.consultationDetails.allDoctors.doctors : [];
        _.forEach(doctorListFromApi, (doctorDetail) => {
            doctorList.push({ value: doctorDetail.id, label: doctorDetail.last_name ? doctorDetail.first_name + ' ' + doctorDetail.last_name : doctorDetail.first_name })
        })
        return doctorList;
    }

    renderReAssignDoctorDrawer = () => {
        const { doctor, reAssignDrawerContent } = this.state;
        const doctorList = this.getAllDoctorsList();
        if (reAssignDrawerContent === 'init') {
            return (
                <div>
                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6re' }}>Re-assign Doctor</Typography>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <TextField
                        inputProps={{ style: { fontFamily: 'Rubik', color: 'black' } }}
                        disabled
                        value={_.truncate(this.props.consultation.summary, 15)}
                        label="Summary"
                        style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem' }} />
                    <TextField
                        inputProps={{ style: { fontFamily: 'Rubik', color: 'black' } }}
                        disabled
                        value={this.props.consultation.customer.contact_number}
                        label="Contact Number"
                        style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem' }} />
                    <TextField
                        inputProps={{ style: { fontFamily: 'Rubik', color: 'black' } }}
                        style={{ width: '11rem', marginLeft: '2vh', marginBottom: '1vh', marginTop: '2rem' }}
                        label="LOP"
                        disabled
                        value={_.capitalize(this.props.consultation.customer.lop)}
                        margin="normal"
                    >

                    </TextField>
                    <TextField
                        inputProps={{ style: { fontFamily: 'Rubik', color: 'black' } }}
                        style={{ width: '11rem', marginLeft: '2vh', marginBottom: '1vh', marginTop: '2rem' }}
                        label="Doctor"
                        disabled
                        value={this.props.consultation && this.props.consultation.doctor ? this.props.consultation.doctor.name : ''}
                        margin="normal"
                    >

                    </TextField>

                    <div style={{ backgroundColor: 'white', marginTop: '2vh', marginLeft: '1rem' }}>
                        <hr style={{ marginLeft: '-2vh', color: '#ffffff' }} />

                    </div>
                    <TextField
                        style={{ display: 'flex', width: '11rem', marginTop: '3vh', marginLeft: '1.3vw' }}
                        select
                        label="Choose Doctor"
                        value={doctor}
                        onChange={this.handleChange('doctor')}
                        margin="normal"
                    >
                        {doctorList.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <div style={{ display: 'flex', float: 'left', width: '20vw', marginTop: '0.5vh', marginLeft: '1vw' }} >
                        <Location style={{ marginTop: '1.2vh' }} />
                        <Typography style={{ textTransform: 'uppercase', fontFamily: 'Rubik-Medium', color: '#172a3a', marginLeft: '0.5vh', marginTop: '1.4vh', fontSize: '1.9vh' }}>{this.props.auth && this.props.auth.currentClinicLocation ? this.props.auth.currentClinicLocation.name : 'loading...'}</Typography>

                    </div>
                    <div style={{
                        position: 'fixed',
                        bottom: '0px',
                        right: '0px', width: '50vh'
                    }}>

                        <Button
                            onClick={this.clickConfirmReAssignDoctor}
                            disabled={!doctor}
                            style={{
                                backgroundColor: !doctor ? 'grey' : 'orange',
                                color: 'white',
                                width: '100%', height: '3rem', textTransform: 'capitalize'
                            }}> Confirm </Button>
                    </div>
                </div>
            );
        } else if (reAssignDrawerContent === 'confirmation') {
            return (
                <div>
                    <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '80%', marginLeft: '35%', marginBottom: '3rem' }}> Are you sure ?</Typography>
                    <div style={{ marginBottom: '30%', marginLeft: '23%', marginRight: '20%' }}>
                        <Button onClick={() => { this.setState({ reAssignDrawerContent: 'init' }) }} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '40%' }}>No</Button>
                        <Button onClick={this.clickYesForDoctorReAssignment} variant="outlined" style={{ marginLeft: '15%', color: 'orange', borderColor: 'orange', width: '40%' }} >Yes</Button>
                    </div>
                </div>
            );
        } else if (reAssignDrawerContent === 'loading') {
            return (
                <div> <CircularProgress style={{ color: 'orange', marginLeft: '11rem', marginTop: '23.5rem', marginBottom: '23rem' }} disableShrink /> </div>
            );
        } else if (reAssignDrawerContent === 'reAssignDoctorApiCalled') {
            if (this.props.consultationDetails && (this.props.consultationDetails.assignDoctorStatus === errors.ERROR_WHILE_ASSIGNING_DOCTOR)) {
                return (
                    <>
                        <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Re-assign doctor</Typography>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                            <BookingFailed style={{ marginLeft: '43%', marginRight: '43%' }} />
                            <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '27%', marginRight: '20%' }}> Error Submitting the Form </Typography>
                            <Button onClick={this.clickTryAgain} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '30%', textTransform: 'capitalize', marginLeft: '35%', marginRight: '35%', marginTop: '2rem' }}>Try Again</Button>
                        </div>
                    </>
                )
            } else if (this.props.consultationDetails && (this.props.consultationDetails.assignDoctorStatus === apiResponse.DOCTOR_ASSIGNED)) {
                return (
                    <div style={{ marginTop: '90%', marginBottom: '30%' }}>
                        <BookingConfirmed style={{ marginLeft: '45%' }} />
                        <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '36%' }}> Doctor Re-assigned</Typography>
                    </div>
                )
            }
        }
    }


    onHover = () => {
        this.setState({ mousePonterInside: true })
    }

    outsideCard = () => {
        this.setState({ mousePonterInside: false })
    }

    renderConsultationCard = () => {
        const consultation = this.props.consultation;
        return (
            <div onMouseOver={this.onHover} onMouseLeave={this.outsideCard} style={{
                marginLeft: '2rem', marginRight: '2rem', borderWidth: '1px 1px 1px 1px', width: '85vw',
                borderColor: '#cbcbcb',
                borderStyle: 'solid',
                borderRadius: '10px',
                marginBottom: '3vh',
                position: 'relative',
            }}>
                <div style={{
                    margin: '1.5vh',
                    marginBottom: '0.5vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        {(consultation && consultation.customer && consultation.customer.name) ? <Typography
                            style={{ float: 'left', marginRight: '0.5vw', display: 'inline-flex', color: '#172a3a', fontFamily: 'Rubik-Medium', fontSize: '1.5vh', marginTop: '1.1vh' }}
                        >
                            {consultation.customer.name}
                        </Typography> : <> </>}
                        {this.props.consultation && this.props.consultation.customer && (this.props.consultation.customer.age || this.props.consultation.customer.age === 0) ? <Typography
                            style={{ float: 'left', marginRight: '0.5vw', marginTop: '1.1vh', display: 'inline-flex', color: '#172a3a', fontFamily: 'Rubik', fontSize: '1.5vh' }}
                        >
                            <span style={{ marginRight: '0.5vw' }}>
                                |
                            </span>
                            {
                                + this.props.consultation.customer.age + ' ' + getAgeUnit(this.props.consultation.customer.age_unit)}
                        </Typography> : <> </>}
                        {this.props.consultation && this.props.consultation.customer && this.props.consultation.customer.contact_number && this.props.consultation.customer.lop ? <Typography
                            style={{ float: 'left', marginRight: '5vw', marginTop: '1.1vh', display: 'inline-flex', color: '#172a3a', fontFamily: 'Rubik', fontSize: '1.5vh', posiiton: 'absolute' }}
                        >
                            <span style={{ marginRight: '0.5vw' }}>
                                |
                            </span>
                            {this.props.consultation.customer.contact_number}
                            <span style={{ marginLeft: '0.5vw' }}>
                                |
                            </span>
                            <span style={{ marginLeft: '0.5vw', textTransform: 'capitalize' }}>
                                {this.props.consultation.customer.lop}
                            </span>
                        </Typography> : <> </>}
                    </div>
                    <div>
                        {consultation && consultation.type === consultationType.TELE ? <Typography style={{ fontSize: '1.1vh', marginTop: '0.1vh', color: '#4D4D4D', }}>(Tele-consultation)</Typography> : <></>}  {/* Show the text Tele-consultation if the consultation type = 'tele' */}
                    </div>

                    {(consultation && consultation.summary) ? <Typography
                        style={{ color: '#172a3a', fontFamily: 'Rubik', fontSize: '1.5vh', position: 'absolute', left: '25vw', top: '2.5vh' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {_.truncate(consultation.summary, {
                                'length': this.state.numberOfCharacters,
                                'separator': ' '
                            })}
                        </div>
                    </Typography> : <> </>}
                    {(consultation.doctor && consultation.doctor.name) ? <div style={{ color: 'black', fontFamily: 'Rubik-medium', fontSize: '1.5vh', position: 'absolute', left: '45vw', top: '2.7vh' }}>{
                        'Dr. ' + consultation.doctor.name
                    }
                        <span style={{ fontFamily: 'Rubik-Regular' }}>
                        </span>
                    </div> : <> </>}
                    {consultation.scheduled_on ? <div style={{ color: 'black', fontFamily: 'Rubik-light', fontSize: '1.5vh', position: 'absolute', left: '57vw', top: '2.7vh' }}>{
                        getISTTimeFormat(consultation.scheduled_on)
                    }
                        <span style={{ fontFamily: 'Rubik-Regular' }}>
                        </span>
                    </div> : <> </>}

                    <div style={{ display: 'flex', paddingLeft: '53vw', marginTop: this.props.consultation.type === consultationType.PHYSICAL ? '-0.1vh' : '-1.4vh', marginBottom: '2.7vh', position: 'relative' }}>
                        {(this.state.mousePonterInside && !(this.props.consultation && this.props.consultation.prescription && this.props.consultation.prescription.vitals) && (this.props.consultation && this.props.consultation.type === consultationType.PHYSICAL)) ?   //Show Enter vitals button only if consultation type === 'physical'
                            <Button onClick={this.pressEnterVitals}
                                variant="filled" style={{ backgroundColor: '#47B29F', position: 'absolute', top: '-2.6vh', right: '8vw' }}>
                                <Typography style={{ fontFamily: 'Rubik', fontSize: '1.3vh', textTransform: 'capitalize', color: 'white' }}> Enter Vitals</Typography>
                            </Button> : <> </>
                        }

                        {(this.state.mousePonterInside && (this.props.auth && this.props.auth.currentClinicLocation && this.props.auth.currentClinicLocation.id === HEADQUARTERS_ID) && (this.props.consultation && this.props.consultation.type === consultationType.TELE) && (this.props.auth && this.props.auth.authDetails && !_.isNil(_.find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER })))) ?   //Show Enter vitals button only if consultation type === 'physical'
                            <Button onClick={this.pressReAssignDoctor}
                                variant="filled" style={{ backgroundColor: '#9298EC', position: 'absolute', top: '-2.6vh', right: '8vw' }}>
                                <Typography style={{ fontFamily: 'Rubik', fontSize: '1.3vh', textTransform: 'capitalize', color: 'white' }}> Re-assign Doctor</Typography>
                            </Button> : <> </>
                        }

                        {this.state.mousePonterInside ? <Button
                            onClick={this.openCancelConsultationDrawer} variant="filled" style={{ backgroundColor: '#F66A6A', marginLeft: '2vw', position: 'absolute', right: '2vw', top: '-2.6vh' }}>
                            <Typography style={{ fontFamily: 'Rubik', fontSize: '1.3vh', textTransform: 'capitalize', color: 'white' }}> Cancel</Typography>
                        </Button> : <> </>}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { position } = this.props;
        return (
            <div className={`upcominConsultation-${position}`}>
                {this.renderConsultationCard()}
                <Drawer disableEnforceFocus anchor="right" open={this.state.drawer} onClose={this.closeVitalsDrawer}>

                    <Paper style={{ width: '50vh', height: '100%' }}>
                        {this.renderDrawerContent()}
                    </Paper>
                </Drawer>

                < Drawer disableEnforceFocus anchor="right" open={this.state.reAssignDrawer} onClose={() => this.closeAssignDoctorDrawer(false)}>

                    <Paper style={{ width: '50vh', height: '100%' }}>
                        {this.renderReAssignDoctorDrawer()}
                    </Paper>
                </Drawer>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
};

export default connect(mapStateToProps, { resetConsultationById, resetAddVitalsStatus, cancelConsultationById, getConsultationsByClinicId, setPresentableConsultationQueue, getConsultationsByClinicIdFirstPage, resetConsultationQPageNumber, resetCancelConsultationStatus, getAllDoctors, resetConsultationDetail, resetDoctorByConsultationId, reAssignDoctorByConsultationId })(ParticularUpcomingC);