import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

class CheckBoxHorizontal extends React.Component {

  render() {
    const { options, selectedOptions = [], selectOption } = this.props;

    return (
      <div className={css.box}>
        {options.map((option) => {
          return (
            <label className={css.container}
              onChange={() => selectOption(option.value)}
              key={option.key}
            >{option.key}
              <input type="checkbox" checked={selectedOptions.includes(option.value) ? "checked" : ''} />
              <span className={css.checkmark} style={{ border: selectedOptions.includes(option.value) ? '1px solid #0033cc' : '' }}></span>
            </label>
          )
        })}
      </div>
    )
  }
}

export default connect(null, null)(CheckBoxHorizontal);