import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import css from './style.module.scss';
import { ReactComponent as PhoneWhite } from '../../../../assets/icons/ticketing/phone-white.svg';
import { ReactComponent as GreenTick } from '../../../../assets/icons/ticketing/green-tick.svg';
import { ReactComponent as Calendar } from '../../../../assets/icons/ticketing/calendar-date.svg';

import BlueButton from '../../../../widgets/blueButton';
import Stepper from '../../../../widgets/stepper';
import { followUpStep, affirmativeReason, nonAffirmativeReason, apiStatus, ticketObjective } from '../../../../constants';
import InputSelector from '../../../../widgets/inputSelector';
import TextBox from '../../../../widgets/textBox';
import { connectTeleCall, resetConnectTeleCall } from '../../../../actions/index';
import { ticketingActions, getAllDoctors, consultationActions } from '../../../../actions';
import AddComments from './addComments';
import CallRecieval from './callReceival';
import FollowUpConsultation from './followUpConsultation';
import BookConsultation from './bookConsultation';
import PlaceConsultation from './placeConsultation';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";

import { createMuiTheme } from '@material-ui/core/styles';
import PrescriptionStep from './prescriptionStep';
import FeedbackStep from './feedbackStep';
import moment from 'moment';

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
});

class FollowUpDetailsCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      callRecieved: true,
      reason: '',
      comment: '',
      callBackTime: null,
      selectedDoctor: '',
      selectedLanguage: '',
      consultationTime: new Date(),
      summary: '',
      consultationNeeded: false,
      showCallRecievedFlow: false,
      showConsultationNeededFlow: false,
      showCommentAddUi: false,
      showPlaceConsultation: false,
      prescriptionRecieved: true,
      showPrescriptionDrawer: false,
      showResponseAddedFlow: false,
      showSendPlaystoreLinkDrawer: false,
    }
  }

  componentDidMount() {
    this.props.resetConnectTeleCall();
    this.props.getAllDoctors(true);
    this.props.resetAddComment();
    this.props.resetFeedbackForm();
  }

  componentDidUpdate(prevProps, prevState) {
    const { resetFeedbackForm } = this.props;
    if (prevProps && prevProps.ticketing && prevProps.ticketing.selectedTicket && this.props.ticketing && this.props.ticketing.selectedTicket && (prevProps.ticketing.selectedTicket.id !== this.props.ticketing.selectedTicket.id)) {
      resetFeedbackForm();
      this.setState({
        step: 0,
        callRecieved: true,
        reason: '',
        comment: '',
        callBackTime: null,
        selectedDoctor: '',
        selectedLanguage: '',
        consultationTime: new Date(),
        summary: '',
        consultationNeeded: false,
        showCallRecievedFlow: false,
        showConsultationNeededFlow: false,
        showCommentAddUi: false,
        showPlaceConsultation: false,
        showSendPlaystoreLinkDrawer: false,
        prescriptionRecieved: true,
        showPrescriptionDrawer: false,
        showResponseAddedFlow: false,
      });
    }
  }

  connectTeleCall = (phoneNumber) => {
    this.props.connectTeleCall(this.props.auth.authDetails.data.user.id, phoneNumber);
  }

  // closeTeleCallDialog = () => {
  //   this.props.resetConnectTeleCall();
  // };

  getAllDoctorsList = () => {
    const doctorList = [];
    const doctorListFromApi = this.props.consultationDetails && this.props.consultationDetails.allDoctors
      ? this.props.consultationDetails.allDoctors.doctors : [];
    _.forEach(doctorListFromApi, (doctorDetail) => {
      doctorList.push({ value: doctorDetail.id, label: doctorDetail.last_name ? doctorDetail.first_name + ' ' + doctorDetail.last_name : doctorDetail.first_name })
    })
    return doctorList;
  }


  setCallRecievedState = () => {
    this.setState({
      callRecieved: true
    });
  }

  resetCallRecievedState = () => {
    this.setState({
      callRecieved: false
    });
  }

  setPrescriptionRecievedState = () => {
    this.setState((prevState) => ({
      prescriptionRecieved: true,
      }));
  }

  resetPrescriptionRecievedState = () => {
    this.setState({ prescriptionRecieved: false, showPrescriptionDrawer: true });
  }

  closePrescriptionDrawer = () => {
    this.setState({ showPrescriptionDrawer: false, prescriptionRecieved: true, })
  }

  setNeedConsultation = () => {
    this.setState({
      consultationNeeded: true
    });
  }

  resetNeedConsultation = () => {
    this.setState({
      consultationNeeded: false
    });
  }


  updateLocalNocomment = (comment) => {
    this.setState({ comment: comment })
  }

  updateLocalcomment = (comment) => {
    this.setState({ comment: comment })
  }


  updateLocalSummary = (summary) => {
    this.setState({ summary: summary })
  }



  renderLine = (text) => {
    return (
      <p className={css.follow_up_details_bottom_paragraph_line}>
        {text}
      </p>
    )
  }

  renderEmptyLine = (text) => {
    return (
      <p className={css.follow_up_details_bottom_empty_space_1}>
        {text}
      </p>
    )
  }


  renderStepperContent = () => {
    const { 
      step, callRecieved, showPlaceConsultation,
      reason, comment, consultationNeeded, prescriptionRecieved,
      consultationTime, selectedDoctor, selectedLanguage, showPrescriptionDrawer,
      summary, showCallRecievedFlow, showConsultationNeededFlow, showResponseAddedFlow,
      showSendPlaystoreLinkDrawer } = this.state;
    const stepArray = this.getAllSteps();
    // ["Call customer", "Response", "Book Follow up"]; -> patient follow up ticket steps
    //  ["Call customer", "Prescription", "Feedback", "Book Follow up"]; -> patient feedback ticket steps
    switch (stepArray[step]) {
      case "Call customer":
        return (
        <>
        {
          !showCallRecievedFlow && 
        <CallRecieval setCallRecievedState={this.setCallRecievedState} resetCallRecievedState={this.resetCallRecievedState} callRecieved={callRecieved} />
        }
        </>
        );
      case "Response":
        return (
          <>
            {
              callRecieved && !showResponseAddedFlow && this.renderAffirmativeResponse()
            }
            {
              !callRecieved && !showResponseAddedFlow && this.renderNonAffirmativeResponse()
            }
            {
              !callRecieved && showResponseAddedFlow && <AddComments reason={reason} comment={comment} />
            }
          </>
        );
      case "Book Follow up":
        return (
          <>
              {
               !showConsultationNeededFlow && <FollowUpConsultation reason={reason} comment={comment}
              setNeedConsultation={this.setNeedConsultation}
              resetNeedConsultation={this.resetNeedConsultation}
              consultationNeeded={consultationNeeded}
              />
              }
              {
                showConsultationNeededFlow && consultationNeeded && !showPlaceConsultation && <BookConsultation
                updateLocalSummary={this.updateLocalSummary}
                consultationTime={consultationTime}
                changeConsultationTime={this.changeConsultationTime}
                selectDoctor={this.selectDoctor}
                selectedDoctor={selectedDoctor}
                selectedLanguage={selectedLanguage}
                selectLanguage={this.selectLanguage}
                summary={summary}
              />
              }
              {
                showConsultationNeededFlow && consultationNeeded && showPlaceConsultation &&
                <PlaceConsultation summary={summary} selectedDoctor={selectedDoctor} selectedLanguage={selectedLanguage} />
              }
          </>
        );
      case "Prescription":
        return (
          <>
          <PrescriptionStep 
          setPrescriptionRecievedState={this.setPrescriptionRecievedState} 
          resetPrescriptionRecievedState={this.resetPrescriptionRecievedState} 
          prescriptionRecieved={prescriptionRecieved} 
          showPrescriptionDrawer={showPrescriptionDrawer}
          closeSendPrescriptionDrawer={this.closePrescriptionDrawer}
          />
          </>
        );
      case "Feedback":
        return(
          <>
          <FeedbackStep showSendPlaystoreLinkDrawer={showSendPlaystoreLinkDrawer} toggleSendPlaystoreLinkDrawer={this.toggleSendPlaystoreLinkDrawer} />
          </>
        );
      default:
        return <> </>;
    }
    
  }

  toggleSendPlaystoreLinkDrawer = () => {
    this.setState((prevState) => ({
      showSendPlaystoreLinkDrawer:
     !prevState.showSendPlaystoreLinkDrawer,
    }));
  }



  getCurrentStepIndex = () => {
    switch (this.state.step) {
      case followUpStep.CALL_RECIEVAL:
        return 1;
      case followUpStep.CALL_RESPONSE:
        return 2;
      case followUpStep.ADD_COMMENT:
        return 3;
      case followUpStep.BOOK_CONSULTATION:
        return 3;
      case followUpStep.PLACE_CONSULTATION:
        return 3;
      default:
        return 1;
    }
  }

  proceedToNextStep = async () => {
      const { markTicketResolve, ticketing, postFeedback, getCustomerPreferences, showPlaceConsultation, extendTicketDeadLine, addCommentsToTicket } = this.props;
      const { selectedTicket } = ticketing;
      const { showConsultationNeededFlow, consultationNeeded, callRecieved, callBackTime, reason } = this.state;
      
      const stepArray = this.getAllSteps();
      switch (stepArray[this.state.step]) {
        case "Book Follow up":
          if (!showConsultationNeededFlow) {
            this.setState({ showConsultationNeededFlow: true });
          }
          if (showConsultationNeededFlow && consultationNeeded) {
            this.setState({ showPlaceConsultation: true });
            markTicketResolve(selectedTicket?.id ? selectedTicket.id : '');
          }
          if (consultationNeeded && showPlaceConsultation) {
            markTicketResolve(selectedTicket?.id ? selectedTicket.id : '');
          }
          if (!showConsultationNeededFlow && !consultationNeeded) {
            console.warn(selectedTicket);
            markTicketResolve(selectedTicket?.id ? selectedTicket.id : '');
          }
          break;
        case "Feedback":
          await postFeedback();
          this.setState((prevState) => ({
            step:
           prevState.step + 1,
          }));
          break;
        case "Call customer":
          if (selectedTicket?.objective === ticketObjective.PATIENT_FEEDBACK && callRecieved) {
            await getCustomerPreferences();
            this.setState((prevState) => ({
              step:
             prevState.step + 1,
            }));
          } else {
            this.setState((prevState) => ({
              step:
             prevState.step + 1,
            }));
          }
          break;
        case "Response":
          if (!callRecieved) {
            this.setState({ showResponseAddedFlow: true});
          } else {
            if(callBackTime) {
              const extendedDeadline = moment(callBackTime).valueOf();
              extendTicketDeadLine(selectedTicket.id, extendedDeadline)
              addCommentsToTicket(selectedTicket.id, reason, null);
            } else {
              this.setState((prevState) => ({
                step:
               prevState.step + 1,
              }));
            }
          }
          break;
        default:
          this.setState((prevState) => ({
            step:
           prevState.step + 1,
          }));
      }
  }

  revertoPreviousStep = () => {
    const { resetAddComment } = this.props;
    const stepArray = this.getAllSteps();
      switch (stepArray[this.state.step]) {
        case "Book Follow up":
          if (this.state.showConsultationNeededFlow) {
              this.setState({ showConsultationNeededFlow: false });
          } else {
            resetAddComment();
            this.setState((prevState) => ({
              step:
             prevState.step - 1,
            }));
          }
          break;
        case "Response":
          resetAddComment();
          this.setState((prevState) => ({
            step:
            prevState.step - 1,
          }));
          break;
        default:
          this.setState((prevState) => ({
            step:
           prevState.step - 1,
          }));
      }
  }


  changeCallBackTime = (date) => {
    this.setState({ callBackTime: date })
  }

  changeConsultationTime = (date) => {
    this.setState({ consultationTime: date })
  }

  renderAffirmativeResponse = () => {
    const { reason, comment } = this.state;
    return (
      <div className={css.affirmative}>
        <div className={css.affirmative_left}>
          <div className={css.affirmative_left_header_1}>
            Select call response
        </div>
          <InputSelector info={'Select only one'} list={[affirmativeReason.FEELING_BETTER_NOW, affirmativeReason.MEDICINE_NOT_USED, affirmativeReason.LAB_TEST_NOT_DONE, affirmativeReason.LAB_TEST_REPORT_AWAITED, affirmativeReason.OTHER_HEALTH_RELATED, affirmativeReason.CALL_BACK_ON_SPECIFIC_DATE]}
            selectOption={this.selectreason}
            selectedOption={reason}
          />

          <div className={css.affirmative_left_header_2}>
            Add comments (Optional)
        </div>
          <TextBox onChange={this.updateLocalcomment} val={comment} />

        </div>

        <div className={css.affirmative_right} style={{ pointerEvents: this.state.reason !== affirmativeReason.CALL_BACK_ON_SPECIFIC_DATE ? 'none' : '', opacity: this.state.reason !== affirmativeReason.CALL_BACK_ON_SPECIFIC_DATE ? '0' : '1' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={materialTheme}>
              <div className={css.dateField1} >
                <Calendar style = {{ marginTop: "6px", paddingRight: "4px" }} />
                <DatePicker
                  //  variant="inline"
                  // className={css.date_input}   

                  value={this.state.callBackTime}
                  placeholder="Enter New Date"
                  // inputVariant="outlined"
                  onChange={this.changeCallBackTime}
                  format="dd-MM-yyyy"
                  inputProps={{ style: { height: 15, width: 125, fontFamily: 'Manrope-Medium', color: '#000000' } }}
                />
              </div>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    )
  }

  selectreason = (reason) => {
    this.setState({ reason: reason })
  }

  selectDoctor = (doctor) => {
    this.setState({ selectedDoctor: doctor })
  }

  selectLanguage = (lang) => {
    this.setState({ selectedLanguage: lang })
  }

  renderNonAffirmativeResponse = () => {
    const { reason, comment } = this.state;
    return (
      <div className={css.non_affirmative}>
        <div className={css.non_affirmative_header_1}>
          Select call response
        </div>
        <InputSelector info={'Select only one'} list={Object.values(nonAffirmativeReason)}
          selectOption={this.selectreason}
          selectedOption={reason}

        />

        <div className={css.non_affirmative_header_2}>
          Add comments
        </div>
        <TextBox onChange={this.updateLocalNocomment} val={comment} />
      </div>
    )
  }

  getAllSteps = () => {
    const { ticketing } = this.props;
    const { selectedTicket } = ticketing;

    if (selectedTicket?.objective === ticketObjective.PATIENT_FOLLOW_UP) {
      if (this.state.callRecieved) {
        return ["Call customer", "Response", "Book Follow up"];
      } else {
        return ["Call customer", "Response"]
      }
    }

    if (selectedTicket?.objective === ticketObjective.PATIENT_FEEDBACK) {
      if (this.state.callRecieved) {
        return ["Call customer", "Prescription", "Feedback", "Book Follow up"];
      } else {
        return ["Call customer", "Response"]
      }
    }

  }

  renderGreenTick = () => {
    return (
      <div className={css.green_tick_container}>
        <GreenTick className={css.green_tick_container_image} />
      </div>
    )
  }

  getSecondButtonText = () => {
    const { step, consultationNeeded, showConsultationNeededFlow } = this.state;
    const stepArray = this.getAllSteps();
    if (showConsultationNeededFlow && consultationNeeded && stepArray[step] === "Book Follow up") {
      return 'Done';
    }
    if (!consultationNeeded && stepArray[step] === "Book Follow up") {
      return 'Done';
    }
    return 'Next';
  }

  clickDone = () => {
    const { extendTicketDeadLine, ticketing } = this.props;
    const { selectedTicket } = ticketing;
    const extendedDeadline = moment(selectedTicket.deadline).add(1, 'd').valueOf();
    if (selectedTicket && selectedTicket.id) {
      extendTicketDeadLine(selectedTicket.id, extendedDeadline)
    }
  }

  shouldBeDisabled = () => {
    const { step, reason, summary, selectedDoctor, 
      selectedLanguage, consultationNeeded, prescriptionRecieved,
      showConsultationNeededFlow } = this.state;
    const { ticketing } = this.props;
    const { markResolveLoading, bookConsultationLoading, addCommentLoading } = ticketing;
    const stepArray = this.getAllSteps();
    if (markResolveLoading || bookConsultationLoading || addCommentLoading) {
      return true;
    }
    if (stepArray[step] === "Response" && !reason) {
      return true;
    }
    if (stepArray[step] === "Book Follow up" && (!summary || !selectedDoctor || !selectedLanguage) && consultationNeeded && showConsultationNeededFlow) {
      return true;
    }
    if (stepArray[step] === "Prescription") {
        return prescriptionRecieved ? false : true;  
    }
    if (stepArray[step] === "Feedback") {
      return ticketing.feedbackForm.rating ? false : true
    }
      return false;
  }

  renderSelectedTicketDetails = () => {
    const { step, callRecieved } = this.state;
    const { ticketing } = this.props;
    const { selectedTicket, addCommentResponse } = ticketing;
    const getSelectedTicketType = () => {
      if (selectedTicket?.objective === ticketObjective.PATIENT_FOLLOW_UP) {
        return { ticketHeader: 'Follow up with patient' }
      }
      if (selectedTicket?.objective === ticketObjective.PATIENT_FEEDBACK) {
        return { ticketHeader: 'Patient feedback' }
      }      
    }
    return (
      <>
        <div className={css.follow_up_details_top}>
          <div className={css.follow_up_details_top_header}>
            <div className={css.follow_up_details_top_header_title}>
              {getSelectedTicketType()?.ticketHeader}
            </div>
            <div className={css.follow_up_details_top_header_list}>

            </div>
          </div>
        </div>
        <div className={css.follow_up_details_divider} />
        <div className={css.follow_up_details_bottom}>
          <div className={css.follow_up_details_bottom_compartment_1}>
            <Stepper steps={this.getAllSteps()} currentStep={this.state.step + 1} />
            <button className={css.call_patient}
              onClick={() => this.connectTeleCall(selectedTicket.metadata && selectedTicket.metadata.consultation && selectedTicket.metadata.consultation.customer && selectedTicket.metadata.consultation.customer && selectedTicket.metadata.consultation.customer.contact_number ? selectedTicket.metadata.consultation.customer.contact_number : '-')} >
              <PhoneWhite className={css.call_patient_icon} />
              Call Patient
            </button>
          </div>
          <div className={css.follow_up_details_bottom_divider} />
          {this.renderStepperContent()}
          <div className={css.follow_up_details_bottom_empty_space_1}>
            {this.renderEmptyLine(".")}
            {this.renderEmptyLine(".")}
          </div>
        </div>
        <div className={css.follow_up_details_footer} >
          <div style={{
            pointerEvents:
              (this.state.step + 1 === 1)
                ||
                ((addCommentResponse === apiStatus.SUCCESS || addCommentResponse === apiStatus.FAILURE) || step === followUpStep.PLACE_CONSULTATION)
                ? 'none' : '',
            opacity:
              (this.state.step + 1 === 1)
                ||
                ((addCommentResponse === apiStatus.SUCCESS || addCommentResponse === apiStatus.FAILURE) || step === followUpStep.PLACE_CONSULTATION)
                ? 0 : 1
          }}> <BlueButton text={"Previous"} onClick={this.revertoPreviousStep} /></div>
          {(addCommentResponse === apiStatus.SUCCESS || addCommentResponse === apiStatus.FAILURE) && !callRecieved ?
            <BlueButton text={'Done'} onClick={this.clickDone} />
            :
            <BlueButton text={this.getSecondButtonText()} onClick={this.proceedToNextStep}
              disabled={this.shouldBeDisabled()}
            />
          }
        </div>

      </>
    )
  }

  render() {
    const { ticketing } = this.props;
    return (
      <div className={css.follow_up_details}>
        {ticketing && ticketing.selectedTicket && ticketing.selectedTicket.id && !ticketing.ticketListLoading ?
          <>
            {this.renderSelectedTicketDetails()}
          </>
          :
          <></>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing, consultationDetails: state.consultationDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectTeleCall: (partnerId, phoneNumber) => { dispatch(connectTeleCall(partnerId, phoneNumber)) },
    resetConnectTeleCall: () => { dispatch(resetConnectTeleCall()) },
    getAllDoctors: () => { dispatch(getAllDoctors()) },
    addCommentsToTicket: (ticketId, reason, comment) => { dispatch(ticketingActions.addCommentsToTicketEvent(ticketId, reason, comment)) },
    markTicketResolve: (ticketId, comment) => { dispatch(ticketingActions.markTicketResolveEvent(ticketId, comment)) },
    extendTicketDeadLine: (ticketId, deadLineTime) => { dispatch(ticketingActions.extendTicketDeadLineEvent(ticketId, deadLineTime)) },
    resetAddComment: () => { dispatch(ticketingActions.resetAddCommentEvent()) },
    bookConsultation: (consultationObject) => { dispatch(consultationActions.bookConsultationEvent(consultationObject)) },
    postFeedback: () => { dispatch(ticketingActions.postFeedbackEvent()) },
    getCustomerPreferences: () => { dispatch(ticketingActions.getCustomerPreferencesEvent()) },
    resetFeedbackForm: () => { dispatch(ticketingActions.resetFeedbackForm()) },
    resetPrescriptionLinkStatus: () => { dispatch(ticketingActions.resetSendPrescriptionLinkSmsApiStatus()) },
    resetPlaystoreLinkStatus: () => { dispatch(ticketingActions.resetSendPlaystoreLinkApiStatus()) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpDetailsCard);