import React from 'react';
import { connect } from 'react-redux';
import css from './style.module.scss';
import TicketList from './ticketList';
import { ticketingActions } from '../../../actions';
import TicketListHeader from './ticketListHeader';
import MedicalDetailsCard from './medicalDetailsCard';
import FollowUpDetailsCard from './followUpDetailsCard';
class TicketDetails extends React.Component {

  state = {
    ticketFilterDrawerStatus: false
  }

  toggleTicketFilterDrawer = () => {
    this.setState((prevState) => ({ ticketFilterDrawerStatus: !prevState.ticketFilterDrawerStatus }))
  }

  render() {
    const { ticketFilterDrawerStatus } = this.state;
    // const { } = this.props;
    return (
      <div className={css.follow_up_details}>
        <div className={css.follow_up_details_list}>
          <div className={css.follow_up_details_list_top}>
            <TicketListHeader toggleTicketFilterDrawer={this.toggleTicketFilterDrawer} />
          </div>
            <TicketList ticketFilterDrawerStatus={ticketFilterDrawerStatus} toggleTicketFilterDrawer={this.toggleTicketFilterDrawer} />


        </div>

        <div className={css.follow_up_details_dynamic_info}>
          {/* <div className={css.follow_up_details_dynamic_info_medical}> */}
          <MedicalDetailsCard />
          {/* </div> */}
          {/* <div className={css.follow_up_details_dynamic_info_follow_up}> */}
          <FollowUpDetailsCard />
          {/* </div>  */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { familyDetails: state.familyDetails, error: state.error, auth: state.auth };
};

const mapDispatchToProps = (dispatch) => ({
  getTickets: (partnerId) => {
    dispatch(ticketingActions.getTicketsEvent(partnerId));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);