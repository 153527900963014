import React, { Component } from "react";
import { connect } from 'react-redux';
import { isEmpty, find, isNil } from 'lodash';
import { buildCustomError } from "../../common/utils";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import { Button, Typography, TextField, Paper, Drawer, FormControl, Select, MenuItem, InputLabel, Snackbar } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles/";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import Plans from './Subscriptions/Plans';
import Members from './BenefitCards/Members';
import Payment from './PaymentDetails/Payment';

import { ReactComponent as EditPencil } from '../../images/EditPencil.svg';
import { getCustomerDetails, updatePrimaryNumber, resetUpdatePrimaryNumberStatus, updateAlternateNumber, resetUpdateAlternateNumberStatus, resetBilling, resetErrors, setError } from '../../actions';
import { accessRole, invoiceStatus } from '../../common/constants';

import orange from '@material-ui/core/colors/orange';
import { errors } from '../../common/errors';
import CustomerDetailsRender from '../salesTask/addCustomerDetails/CustomerDetailsRender';
import EditButton from '../common/EditButton/EditButton';
import AddressDetails from './AddressDetails/AddressDetails';
import BusinessDetails from './BusinessDetails/BusinessDetails';
import NammaClinicCenter from './NammaClinicCenter/NammaClinicCenter';
import RenewalStatusCta from './RenewalStatusCta/RenewalStatusCta';
import RenewalsDrawer from "./RenewalsDrawer/RenewalsDrawer";
import Billing from "../BillingTask/Billing/Billing";
import History from "../BillingTask/History/History";
import style from './style';
import { color } from "../../constants/colors";
import { billingType, lsqActivityCode } from '../../constants/index';
import { leadService } from "../../clients/leadService";
import { subscriptionStatus } from "../../common/constants";
import CopyIcon from "../../assets/icons/copyIcon";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checkedIcon.svg";
import moment from "moment";

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});
class DetailPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: 0, // change to 0   //RE_SET
            editRmnDialog: false,
            updatedRmn: '',
            retypedRmn: '',
            rmnSubmitButtonPressed: false,
            isAlternateNumberFlow: false,
            onboard: false,
            selectedSubscription: '',
            openAddressDrawer: false,
            openNammaClinicDrawer: false,
            businessDrawer: false,
            renewalsDrawer: false,
            activityDrawer: false,
            purposeOfVisit: '',
            marketingChannel: '',
            marketingChannelRemarks: '',
            submittingData: false,
            draftSavedDialog: false,
            showSnackbar: false,
            snackbarMessage: '',
            showCheckedIcon: false,
            iconColor: color.greys[100],
            bgForCopyOrChecked: "copyIcon",
        };

        this.eVitalRxTab = React.createRef(null);
    }

    

    componentDidMount() {
        const { consultationDetails } = this.props;
        if (window.location.hash === '#history') {
            this.setState({
                activeTabIndex: 4,
            });
        }
        if (window.location.hash === '#billing') {
            this.setState({
                activeTabIndex: 3,
            });
        }
        if (window.location.hash === '#plans') {
            this.setState({
                activeTabIndex: 0,
            });
        }
        if (window.location.hash === '#consultation') {
            this.setState({
                activeTabIndex: 1,
            });
        }

        const evital_redirect_url = consultationDetails.billing?.evital_redirect_url;
        if (consultationDetails.billing?.for === billingType.CONSULTATION && consultationDetails.billing.evital_redirect_url) {
            this.eVitalRxTab.current = window.open(evital_redirect_url, "_blank");
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { resetBilling, consultationDetails, familyDetails } = this.props;
        const prevBillState = prevProps.consultationDetails?.bill;
        const newBillState = consultationDetails?.bill;
        if (prevBillState !== null) {
            if ((prevBillState?.isLoading && !newBillState?.isLoading)
                && (newBillState.url !== null)) {
                if (window.location.hash === '#plans') {
                    this.setState({
                        activeTabIndex: 0,
                    });
                }
                resetBilling();
            }
        }

        if (prevProps.familyDetails !== familyDetails
            && familyDetails.invoicesByFamilyId?.apiStatus === 'success'
            && !isEmpty(familyDetails.invoicesByFamilyId.data.invoices)) {
            const listOfInvoices = familyDetails.invoicesByFamilyId?.data?.invoices ?? [];
            const invoicesForRenewal = listOfInvoices.filter(invoice => invoice?.status === invoiceStatus.UNPAID
                && (invoice.subscription?.status === subscriptionStatus.ACTIVE
                    || invoice.subscription?.status === subscriptionStatus.PENDING)
                && moment(invoice.subscription.end_date).isBetween(moment().startOf('month'), moment().endOf('month')));
            if (!isEmpty(invoicesForRenewal)) {
                this.setState({
                    invoicesToRenew: invoicesForRenewal,
                });
            }
        }
    }

    handleChange = (event, value) => {
        this.setState({ activeTabIndex: value });
        if (value === 4) {
            window.location.hash = "#history";
        }
    };

    markSelectedSubscription = (value) => {
        this.setState({ selectedSubscription: value, onboard: true });
    }

    resetSelectedSubscription = () => {
        this.setState({ selectedSubscription: '', onboard: false, });
        if (this.props.familyDetails && this.props.familyDetails.customerPhoneNumber) {
            this.props.getCustomerDetails(this.props.familyDetails.customerPhoneNumber);
        }
    }

    handleSubmit = (updateNumberObject) => {
        this.props.resetUpdatePrimaryNumberStatus();
        this.props.resetUpdateAlternateNumberStatus();
        this.setState({ rmnSubmitButtonPressed: true });
        const familyId = this.props.familyDetails && this.props.familyDetails.currentCustomer && this.props.familyDetails.currentCustomer.family ? this.props.familyDetails.currentCustomer.family.id : '';
        if(this.state.isAlternateNumberFlow){
            const customerId = this.props.familyDetails && this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.id : ''; 
            const updateAlternateNumberPromise = this.props.updateAlternateNumber(customerId, {alternate_number: updateNumberObject?.repeat_new_number});
            updateAlternateNumberPromise.then(() => {
                if (this.props.familyDetails && this.props.familyDetails.updateAlternateNumberStatus && this.props.familyDetails.updateAlternateNumberStatus.status === 204) {
                    this.props.getCustomerDetails(this.props.familyDetails.currentCustomer?.primary_number);
                    this.setState({
                        editRmnDialog: false, updatedRmn: '',
                        retypedRmn: '', rmnSubmitButtonPressed: false,
                        isAlternateNumberFlow:false
                    })
                }
            })
        }
        else{
            const updatePrimaryNumberPromise = this.props.updatePrimaryNumber(familyId, updateNumberObject);
            // const getCustomerDetailsPromise = updatePrimaryNumberPromise.then(this.props.getCustomerDetails(this.state.retypedRmn));
            // getCustomerDetailsPromise.then(this.setState({ rmnSubmitButtonPressed: false  }))
            updatePrimaryNumberPromise.then(() => {
                if (this.props.familyDetails && this.props.familyDetails.updateRmnStatus && this.props.familyDetails.updateRmnStatus.status === 204) {
                    this.props.getCustomerDetails(this.state.retypedRmn);
                    this.setState({
                        editRmnDialog: false, updatedRmn: '',
                        retypedRmn: '', rmnSubmitButtonPressed: false,
                        isAlternateNumberFlow:false
                    })
                }
            })
        }
    }

    handleCancel = () => {
        this.setState({
            editRmnDialog: false, rmnSubmitButtonPressed: false, updatedRmn: '',
            retypedRmn: '',
            isAlternateNumberFlow:false
        });
        this.props.resetUpdatePrimaryNumberStatus();
        this.props.resetUpdateAlternateNumberStatus();
    }

    editRmnButton = () => {
        this.setState({ editRmnDialog: true });
        this.props.resetUpdatePrimaryNumberStatus();
        this.props.resetUpdateAlternateNumberStatus();
    }

    editAlternateNumberButton = () => {
        this.setState({ editRmnDialog: true });
        this.setState({ isAlternateNumberFlow: true });
        this.props.resetUpdatePrimaryNumberStatus();
        this.props.resetUpdateAlternateNumberStatus();
    }

    editNammaClinicCenter = () => {
        this.setState({
            openNammaClinicDrawer: true,
        })
    }

    renderUpdatePrimaryNumberErrorMesssage = () => {
        let error = '';
        if (this.state.updatedRmn.length === 10 && this.state.retypedRmn.length === 10 && (this.state.updatedRmn !== this.state.retypedRmn)) {
            error = 'Numbers do not match'
        }
        // if (this.state.updatedRmn.length === 10 && this.state.retypedRmn.length === 10 && (this.state.updatedRmn === this.state.retypedRmn) && this.props.familyDetails.updateRmnStatus === errors.ERROR_IN_UPDATING_PRIMARY_NUMBER) {
        //     return 'Error while updating RMN'
        // }
        if (this.props.familyDetails && (this.props.familyDetails.updateRmnStatus === errors.ERROR_NUMBER_ALREADY_IN_USE)) {
            error = 'Number already in use'
        }
        if (this.props.familyDetails && (this.props.familyDetails.updateAlternateNumberStatus === errors.ERROR_NUMBER_ALREADY_IN_USE)) {
            error = 'Number already in use'
        }
        if (this.state.isAlternateNumberFlow && this.state.updatedRmn.length === 10 && this.state.retypedRmn.length === 10 && (this.state.updatedRmn === this.state.retypedRmn) && (this.state.updatedRmn === this?.props?.familyDetails?.currentCustomer?.primary_number)) {
            error = 'RMN and alternate number can not be same.'
        }
        return error;
    }

    handleEdit(drawer) {
        this.setState({
            [drawer]: true,
        })
    }

    closeDrawer(key) {
        this.setState({
            [key]: false,
        })
    }

    handleRenewalCtaOnClick = () => {
        const { familyDetails } = this.props;
        if (familyDetails.invoicesByFamilyId?.apiStatus === 'success'
            && !isEmpty(familyDetails.invoicesByFamilyId.data.invoices)) {
            const listOfInvoices = familyDetails.invoicesByFamilyId?.data?.invoices ?? [];
            const invoicesForRenewal = listOfInvoices.filter(invoice => invoice.status === invoiceStatus.UNPAID);
            if (!isEmpty(invoicesForRenewal)) {
                this.setState({
                    renewalsDrawer: true,
                });
            }
        }
    };

    handleCopyText = (customer) => {
        navigator.clipboard.writeText(customer.family?.id);
        this.setState({
            showCheckedIcon: true, 
            bgForCopyOrChecked: "checkedIcon",
        }, ()=> {
            setTimeout(()=> {
                this.setState({
                    showCheckedIcon: false, 
                    bgForCopyOrChecked: "copyIcon"})
            },4000)
        })
    };

    renderAppBar = () => {
        const { activityDrawer, showCheckedIcon, iconColor} = this.state;
        const updatePrimaryNumberObject = {
            old_number: this.props.familyDetails && this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.primary_number : '',
            new_number: this.state.updatedRmn,
            repeat_new_number: this.state.retypedRmn,
        }
        const updateAlternateNumberObject = {
            old_number: this.props.familyDetails && this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.alternate_number : '',
            new_number: this.state.updatedRmn,
            repeat_new_number: this.state.retypedRmn,
            primary_number: this.props.familyDetails && this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.primary_number : '',
        }
        const { classes, familyDetails, consultationDetails } = this.props;
        const {
            activeTabIndex, openAddressDrawer, openNammaClinicDrawer, businessDrawer, renewalsDrawer,
            invoicesToRenew,
        } = this.state;
        const customer = this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer : '';
        const toggleActivityDrawer = () => {
            const { activityDrawer } = this.state;
            this.setState({
                activityDrawer: !activityDrawer,
                purposeOfVisit: '',
                marketingChannel: '',
            })
        };

        /**
         * All possible values for purposes of customer visits
         * to the CHH. These values are used to log Activities
         * on LSQ, regardless of plan status.
         */
        const purposeOfVisitList = [
            {
                label: 'Consultation',
                value: 'consultation',
            },
            {
                label: 'Consultation - Followup',
                value: 'consultation_follow_up',
            },
            {
                label: 'High Touch Onboarding',
                value: 'high_touch_onboarding',
            },
            {
                label: 'Basic Vitals',
                value: 'basic_vitals',
            },
            {
                label: 'Just Medicines',
                value: 'medicines',
            },
            {
                label: 'Just Lab Tests',
                value: 'lab_tests',
            },
            {
                label: 'Just Visiting',
                value: 'visit',
            },
            {
                label: 'Purchase Plan',
                value: 'purchase_plan'
            },
            {
                label: 'Inbound Call',
                value: 'inbound_call',
            },
        ];

        /**
         * All possible values for purposes of customer visits
         * to the CHH. These values are used to log Activities
         * on LSQ, regardless of plan status.
         */
        const marketingChannelList = [
            {
                label: 'CHH Outlet',
                value: 'chh',
            },
            {
                label: 'SMS',
                value: 'sms',
            },
            {
                label: 'IVR Call',
                value: 'ivr',
            },
            {
                label: 'Facebook',
                value: 'facebook',
            },
            {
                label: 'Google',
                value: 'google',
            },
            {
                label: 'Leaflet/Poster',
                value: 'leaflet',
            },
            {
                label: 'Newspaper',
                value: 'newspaper',
            },
            {
                label: 'Coupon',
                value: 'coupon'
            },
            {
                label: 'Family or Friends',
                value: 'family_or_friends',
            },
            {
                label: 'Canopy Activity',
                value: 'canopy_activity',
            },
            {
                label: 'Existing Customer',
                value: 'existing_customer'
            },
            {
                label: 'Practo Consultation',
                value: 'practo_consultation'
            },
            {
                label: 'Other',
                value: 'other',
            },
        ];

        const handlePurposeOfVisitOnChange = (event) => {
            const selectedOption = event.target.value;
            this.setState({
                purposeOfVisit: selectedOption,
            });
        };

        const handleMarketingChannelOnChange = (event) => {
            const selectedOption = event.target.value;
            this.setState({
                marketingChannel: selectedOption,
            });
        };

        const renderActivityDrawer = () => {
            const { purposeOfVisit, marketingChannel, marketingChannelRemarks } = this.state;
            const { familyDetails, auth, classes, setError, newCustomer } = this.props;
            const accessToken = auth.authDetails.data.access_token;
            const currentCHH = auth.currentClinicLocation.name;
            const headSubscriber = familyDetails.allMembers.members.filter(member => {
                return member.relationship === 'self';
            })[0];
            const submitPurposeOfVisit = () => {
                const purposeOfVisitLabel = purposeOfVisitList.filter(option => {
                    return option.value === purposeOfVisit;
                })[0].label;

                const marketingChannelLabel = marketingChannelList.filter(option => {
                    return option.value === marketingChannel;
                })[0].label;

                const customerAddresses = newCustomer?.addresses;

                const activityPayload = {
                    activity_event: lsqActivityCode.PURPOSE_OF_VISIT_ACTIVITY,
                    activity_note: currentCHH,
                    phone: headSubscriber.primary_number,
                    first_name: headSubscriber.first_name,
                    last_name: headSubscriber.last_name,
                    gender: headSubscriber.gender,
                    fields: {
                        purpose_of_visit: purposeOfVisitLabel,
                        marketing_channel: marketingChannelLabel !== 'Other'
                            ? marketingChannelLabel
                            : `Other - ${marketingChannelRemarks}`,
                    },
                    lop: familyDetails.currentCustomer.lop,
                    dob: familyDetails.currentCustomer.dob,
                    ...(Boolean(familyDetails.currentCustomer?.preferences?.whatsapp) && {whatsapp_consent: familyDetails.currentCustomer?.preferences?.whatsapp?.consent}),
                    ...(Boolean(customerAddresses && customerAddresses.length > 0 && customerAddresses[0]?.state) && ({ state: customerAddresses[0].state })),
                    ...(Boolean(customerAddresses && customerAddresses.length > 0 && customerAddresses[0]?.city) && ({ city: customerAddresses[0].city })),
                    ...(Boolean(customerAddresses && customerAddresses.length > 0 && customerAddresses[0]?.zipcode) && ({ pincode: customerAddresses[0].zipcode })),
                    clinic_id: auth.currentClinicLocation.id,
                };
                this.setState({
                    submittingData: true,
                }, async () => {
                    try {
                        await leadService.createActivity(accessToken, activityPayload);
                        this.setState({
                            activityDrawer: false,
                            submittingData: false,
                            showSnackbar: true,
                            snackbarMessage: "Purpose of visit recorded!"
                        }, () => {
                            // redirect the user to the consultations tab if the purpose of visit
                            // is consultation related;
                            if ([purposeOfVisitList[0].value, purposeOfVisitList[1].value].includes(purposeOfVisit)) {
                                this.setState({
                                    activeTabIndex: 1,
                                });
                                window.location.hash = "#consultation";
                            }
                        });
                    }
                    catch (e) {
                        const error = buildCustomError(e);
                        setError(error);
                        this.setState({
                            showSnackbar: true,
                            snackbarMessage: 'The visit could not be recorded. Please try again!',
                        });
                    }
                });
            };

            const handleRemarksOnChange = (event) => {
                const remarksValue = event.target.value;
                this.setState({
                    marketingChannelRemarks: remarksValue,
                });
            }

            return (
                
                    <Drawer
                        disableEnforceFocus
                        anchor="right"
                        open={activityDrawer}
                        onClose={toggleActivityDrawer}
                    >
                        <div style={{ width: '360px', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div
                                style={{
                                    height: 'calc(50px - 4vh)',
                                    borderBottom: `1px solid ${color.ROYAL_ORANGE}`,
                                    padding: '3vh 2vh 1vh 2vh',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Rubik',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Record Visit
                                </Typography>
                                <CloseIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={toggleActivityDrawer}
                                />
                            </div>
                            <div
                                style={{
                                    width: 'calc(100% - 4vh)',
                                    padding: '2vh',
                                    height: 'calc(100% - 50px - 4vh)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                    >
                                        <InputLabel
                                            style={{
                                                fontFamily: 'Rubik',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                            }}
                                        >
                                            Purpose of Visit
                                        </InputLabel>
                                        <Select
                                            defaultValue={purposeOfVisitList[0].value}
                                            value={purposeOfVisit}
                                            onChange={handlePurposeOfVisitOnChange}
                                            style={{
                                                fontFamily: 'Rubik',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {
                                                purposeOfVisitList.map(option => {
                                                    return (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}
                                                            style={{
                                                                fontFamily: 'Rubik',
                                                                fontWeight: '400',
                                                                fontSize: '16px',
                                                            }}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        variant="standard"
                                        style={{
                                            marginTop: '2vh',
                                        }}
                                        fullWidth
                                    >
                                        <InputLabel
                                            style={{
                                                fontFamily: 'Rubik',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                            }}
                                        >
                                            Marketing Channel
                                        </InputLabel>
                                        <Select
                                            defaultValue={marketingChannelList[0].value}
                                            value={marketingChannel}
                                            onChange={handleMarketingChannelOnChange}
                                            style={{
                                                fontFamily: 'Rubik',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {
                                                marketingChannelList.map(option => {
                                                    return (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}
                                                            style={{
                                                                fontFamily: 'Rubik',
                                                                fontWeight: '400',
                                                                fontSize: '16px',
                                                            }}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    {Boolean(marketingChannel === 'other') &&
                                        <TextField
                                            value={marketingChannelRemarks}
                                            label="Remarks"
                                            onChange={handleRemarksOnChange}
                                            style={{
                                                width: '100%',
                                                fontFamily: 'Rubik',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                marginTop: '1vh'
                                            }}
                                            multiline
                                        />}
                                </div>
                                <Button
                                    className={classes.submitButton}
                                    onClick={submitPurposeOfVisit}
                                    disabled={!purposeOfVisit
                                        || !marketingChannel}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Drawer>
                
            )
        };
        return (
            <div style={{ position: 'relative' }} >
                <Drawer anchor="right"
                    disableEnforceFocus
                    open={openAddressDrawer}
                    onClose={() => this.closeDrawer('openAddressDrawer')}>
                    <Paper style={{ width: '50vh', height: '100%' }}>
                        <AddressDetails closeAddressDrawer={() => this.closeDrawer('openAddressDrawer')} />
                    </Paper>
                </Drawer>
                <Drawer anchor="right"
                    disableEnforceFocus
                    open={businessDrawer}
                    onClose={() => this.closeDrawer('openAddressDrawer')}>
                    <Paper style={{ width: '50vh', height: '100%' }}>
                        <BusinessDetails closeBusinessesDrawer={() => this.closeDrawer('businessDrawer')} />
                    </Paper>
                </Drawer>
                <Drawer anchor="right"
                    disableEnforceFocus
                    open={openNammaClinicDrawer}
                    onClose={() => this.closeDrawer('openNammaClinicDrawer')}>
                    <Paper style={{ width: '50vh', height: '100%' }}>
                        <NammaClinicCenter closeNammaClinicDrawer={() => this.closeDrawer('openNammaClinicDrawer')} />
                    </Paper>
                </Drawer>
                <Drawer anchor="right"
                    disableEnforceFocus
                    open={renewalsDrawer}
                    onClose={() => this.closeDrawer('renewalsDrawer')}>
                    <Paper style={{ width: '50vh', height: '100vh' }}>
                        <RenewalsDrawer closeDrawer={() => { this.closeDrawer('renewalsDrawer') }} invoices={invoicesToRenew} />
                    </Paper>
                </Drawer>
                <Paper className={classes.root} elevation={0}>
                    <div className={classes.customerDetailsBox}>
                        <div className={classes.customerDetails}>
                            <Typography className={classes.bold}> {customer.last_name ? customer.first_name + ' ' + customer.last_name : customer.first_name} </Typography>
                        </div>
                        <span> | </span>

                        <div className={classes.customerDetails}>
                            <Typography className={classes.bold}> Clinikk ID: </Typography>
                            <div 
                            onClick={()=>this.handleCopyText(customer)} 
                            className={`${classes.idContainer} ${classes[this.state.bgForCopyOrChecked]}`}
                            > 
                            <div>
                            <Typography className={classes.ml_4}>
                                {customer.family ? customer.family.id : ''}
                                </Typography> 
                            </div>  
                            <div className={`${classes.copyCheckedIcon}`}
                                onMouseOver={()=>this.setState({iconColor: color.ROYAL_ORANGE})}
                                onMouseOut={()=>this.setState({iconColor: color.greys[100]})}
                            >
                                {showCheckedIcon? <CheckedIcon /> : <CopyIcon color={iconColor} />}</div>
                            </div>  
                        </div>
                        <span> | </span>

                        <div className={`${classes.customerDetails} ${classes.royalOrange}`}>
                            <Typography className={classes.bold}> Center: </Typography>
                            <Typography className={classes.ml_4}>
                                {
                                    familyDetails && familyDetails.allMembers && familyDetails.allMembers.members[0] && familyDetails.allMembers.members[0].metadata
                                        && familyDetails.allMembers.members[0].metadata.nc_center ? find(consultationDetails.allClinics.clinics, clinic => clinic.id
                                            === familyDetails.allMembers.members[0].metadata.nc_center.id)?.name + '  ' : '-'
                                }
                                <EditPencil className={`${classes.ml_4} ${classes.cursorPointer}`} onClick={this.editNammaClinicCenter}></EditPencil>
                            </Typography>
                        </div>
                        <span> | </span>

                        <div className={`${classes.customerDetails} ${classes.royalOrange}`}>
                            <Typography className={classes.bold}> RMN: </Typography>
                            <Typography className={classes.ml_4}>
                                {this.props.familyDetails && this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.primary_number + '   ' : '-'}
                                {/* show edit button only for HA Manager */}
                                {this.props.auth && this.props.auth.authDetails && !isNil(find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER }))
                                    ? <EditPencil className={`${classes.ml_4} ${classes.cursorPointer}`} onClick={this.editRmnButton}></EditPencil> : <> </>}
                            </Typography>
                        </div>
                        <span> | </span>
                        <div className={`${classes.customerDetails} ${classes.royalOrange}`}>
                            <Typography className={classes.bold}> Alternate No.: </Typography>
                            <Typography className={classes.ml_4}>
                                {this.props.familyDetails && this.props.familyDetails.currentCustomer && this.props.familyDetails.currentCustomer?.alternate_number ? this.props.familyDetails.currentCustomer.alternate_number + '   ' : '-'}
                                {/* show edit button only for HA Manager */}
                                {this.props.auth && this.props.auth.authDetails && !isNil(find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER }))
                                    ? <EditPencil className={`${classes.ml_4} ${classes.cursorPointer}`} onClick={this.editAlternateNumberButton}></EditPencil> : <> </>}
                            </Typography>
                        </div>
                        <div className={`${classes.customerDetails}`}>
                            <EditButton handleEdit={() => this.handleEdit('openAddressDrawer')} color='orangeColor' title={'Addresses'} />
                        </div>
                        <div className={`${classes.customerDetails}`}>
                            <EditButton handleEdit={() => this.handleEdit('businessDrawer')} color='orangeColor' title={'Business'} />
                        </div>
                    </div>
                    <div >
                        <AppBar position="static" elevation={0} style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div className={`${classes.appBarColumn} ${classes.tabs}`}>
                                <Tabs value={activeTabIndex} onChange={this.handleChange} style={{ textTransform: "capitalize", backgroundColor: "white", fontWeight: 'bold' }} classes={{ indicator: classes.indicator }} >
                                    <Tab
                                        className=
                                        {this.state.activeTabIndex === 0 ? classes.active_tab : classes.default_tabStyle}
                                        style={{ color: 'black', textTransform: "capitalize" }}
                                        label="Plans" />
                                    <Tab
                                        className=
                                        {this.state.activeTabIndex === 1 ? classes.active_tab : classes.default_tabStyle}
                                        style={{ color: 'black', textTransform: "capitalize" }}
                                        label="Members" />
                                    <Tab
                                        className=
                                        {this.state.activeTabIndex === 2 ? classes.active_tab : classes.default_tabStyle}
                                        style={{ color: 'black', textTransform: "capitalize" }}
                                        label="Payment Details" />
                                    <Tab
                                        className={this.state.activeTabIndex === 3 ? classes.active_tab : classes.default_tabStyle}
                                        style={{ color: 'black', textTransform: "capitalize" }}
                                        label="Generate Bill" />
                                    <Tab
                                        className={this.state.activeTabIndex === 4 ? classes.active_tab : classes.default_tabStyle}
                                        style={{ color: 'black', textTransform: "capitalize" }}
                                        label="Billing History" />
                                </Tabs>
                            </div>
                            <div className={`${classes.appBarColumn} ${classes.badges}`}>
                                {(activeTabIndex !== 3) && <>
                                    <Button className={classes.recordActivityCta} onClick={toggleActivityDrawer}>
                                        Record Visit
                                    </Button>
                                    {renderActivityDrawer()}
                                </>}
                                <RenewalStatusCta
                                    invoices={invoicesToRenew}
                                    onClick={() => {
                                        this.handleRenewalCtaOnClick();
                                    }}
                                />
                            </div>
                        </AppBar>
                        {/* {componentToShow} */}
                        {/* <LoadingMembers/> */}
                        {/* <Plans /> */}
                        {/* <Members /> */}
                        {/* {this.renderComponent[this.state.activeTabIndex]} */}
                    </div>
                </Paper>
                <Dialog
                    style={{
                        height: '100%', marginLeft: '30%', marginRight: '50%',
                        width: '80vh',
                        borderRadius: '100px'
                    }}
                    open={this.state.editRmnDialog}
                    // open = {true}
                    // onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {(this.state.rmnSubmitButtonPressed && (this.props.familyDetails.updateRmnStatus === null && this.props.familyDetails.updateAlternateNumberStatus === null)) ? <div>
                        <Paper style={{ position: 'relative', height: '35vh', width: '50vh' }}>
                            <CircularProgress style={{ color: 'orange', marginLeft: '45%', marginRight: '50%', marginTop: '32%', marginBottom: '35%' }} />
                        </Paper>
                    </div> :
                        <div style={{ position: 'relative', height: '35vh', width: '50vh' }}>
                            <ThemeProvider theme={materialTheme}>
                                <Typography style={{ textAlign:'center', fontFamily: 'Rubik-Medium', fontSize: '2.5vh', marginTop: '2vh' }}> {this.state.isAlternateNumberFlow ? 'Add/Edit Alternate Number' : 'Edit RMN'}</Typography>

                                {/* </DialogTitle> */}
                                {/* <DialogContent> */}
                                {/* <DialogContentText id="alert-dialog-description"> */}
                                <TextField style={{ marginLeft: '15%', marginRight: '15%', width: '70%', marginTop: '2vh', color: 'orange' }}
                                    type="text"
                                    label="Enter Number"
                                    value={this.state.updatedRmn}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                    onChange={(event) => this.setState({ updatedRmn: event.target.value })}
                                > </TextField>
                                <TextField style={{ marginLeft: '15%', marginRight: '15%', width: '70%', marginTop: '2vh', color: 'orange' }}
                                    type="text"
                                    label="Confirm Number"
                                    value={this.state.retypedRmn}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                    onChange={(event) => this.setState({ retypedRmn: event.target.value })}
                                    helperText={this.renderUpdatePrimaryNumberErrorMesssage()}
                                    error={this.renderUpdatePrimaryNumberErrorMesssage().length > 0 || this.renderUpdatePrimaryNumberErrorMesssage() === 'Numbers do not match' || this.renderUpdatePrimaryNumberErrorMesssage() === 'Number already in use' || this.renderUpdatePrimaryNumberErrorMesssage() === 'Error while updating RMN'}
                                > </TextField>
                            </ThemeProvider>
                            {/* </DialogContentText> */}
                            {/* </DialogContent> */}
                            {/* <DialogActions> */}
                            <div style={{ height: '2vh', marginTop: '4vh', marginBottom: '6vh' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => this.handleCancel()}
                                    style={{ borderColor: 'orange', color: 'orange', backgroundColor: 'white', textTransform: 'capitalize', marginLeft: '17.5%', width: '30%', marginBottom: '2vh', marginRight: '5%' }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => this.handleSubmit(this.state.isAlternateNumberFlow ? updateAlternateNumberObject : updatePrimaryNumberObject)}
                                    style={{
                                        color: 'white',
                                        backgroundColor: (this.state.updatedRmn.length !== 10 || this.state.retypedRmn.length !== 10 || this.renderUpdatePrimaryNumberErrorMesssage().length > 0) ? 'grey' : ' #f38b44',
                                        textTransform: 'capitalize', marginRight: '17.5%', width: '30%', marginBottom: '2vh'
                                    }}
                                    color="primary"
                                    disabled={this.state.updatedRmn.length !== 10 || this.state.retypedRmn.length !== 10 || this.renderUpdatePrimaryNumberErrorMesssage().length > 0}
                                    autoFocus>
                                    Submit
                                </Button>
                            </div>
                        </div>}
                    {/* </DialogActions> */}
                </Dialog>
            </div>
        )
    }

    render() {
        const { activeTabIndex, showSnackbar, snackbarMessage } = this.state;
        const { error } = this.props;
        return (
            <div style={{ marginLeft: '2vh', marginTop: '1vh', marginBottom: '0vh', marginRight: '1vh',  width: '100vw', height: '90vh', background: 'white' }}>
                {this.renderAppBar()}
                {activeTabIndex === 0 && <>
                    {this.state.onboard ? <CustomerDetailsRender subscription={this.state.selectedSubscription} onClose={this.resetSelectedSubscription} /> : <Plans subscriptionMark={this.markSelectedSubscription} />}
                </>}
                {activeTabIndex === 1 && <>
                    <Members />
                </>}
                {activeTabIndex === 2 && <>
                    <Payment />
                </>}
                {activeTabIndex === 3 && <>
                    <Billing eVitalRxTab={this.eVitalRxTab} />
                </>}
                {activeTabIndex === 4 && <>
                    <History />
                </>}
                {showSnackbar && <Snackbar
                    autoHideDuration={2000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={showSnackbar}
                    onClose={() => {
                        resetErrors();
                        this.setState({
                            snackbarMessage: '',
                            showSnackbar: false,
                        });
                    }}
                >
                    <MuiAlert
                        severity={error.error?.message ? "error" : "success"}
                        elevation="6"
                        variant="filled"
                    >

                        {error.error?.message ?? snackbarMessage}
                    </MuiAlert>
                </Snackbar>}
            </div>
        )
    };
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        error: state.error,
        familyDetails: state.familyDetails,
        consultationDetails: state.consultationDetails,
        newCustomer: state.newCustomer,
    };
};

export default connect(mapStateToProps, {
    getCustomerDetails,
    updatePrimaryNumber,
    updateAlternateNumber,
    resetUpdatePrimaryNumberStatus,
    resetUpdateAlternateNumberStatus,
    resetBilling,
    resetErrors,
    setError,
})(withStyles(style)(DetailPage));