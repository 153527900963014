import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

import ProgressIndicator from '../../../../../widgets/progressIndicator';
import { ticketingActions, consultationActions } from '../../../../../actions';
import { ReactComponent as GreenTick } from '../../../../../assets/icons/ticketing/green-tick.svg';
import { apiStatus } from '../../../../../constants';
import { consultationType, HEADQUARTERS_ID } from '../../../../../common/constants';

class PlaceConsultation extends React.Component {

  // renderMedicalDetailsCard = () => {
  //   const { ticketing } = this.props;

  //   return (
  //     <>
  //       <div className={css.add_comments}>

  //     </>
  //   )
  // }

  componentDidMount() {
    const { ticketing, bookConsultation, auth, summary, selectedDoctor, selectedLanguage } = this.props;
    const { selectedTicket } = ticketing;
    const consultationObject = {
      type: (this.props.auth && this.props.auth.currentClinicLocation && this.props.auth.currentClinicLocation.id === HEADQUARTERS_ID) ? consultationType.TELE : consultationType.PHYSICAL,
      customer: {
        id: selectedTicket.metadata && selectedTicket.metadata.consultation && selectedTicket.metadata.consultation.customer && selectedTicket.metadata.consultation.customer && selectedTicket.metadata.consultation.customer.id ? Number(selectedTicket.metadata.consultation.customer.id) : '-',
        lop: selectedLanguage ? selectedLanguage : '',
        contact_number: selectedTicket.metadata && selectedTicket.metadata.consultation && selectedTicket.metadata.consultation.customer && selectedTicket.metadata.consultation.customer && selectedTicket.metadata.consultation.customer.contact_number ? selectedTicket.metadata.consultation.customer.contact_number : '-',
      },
      clinic: {
        id: auth && auth.currentClinicLocation && auth.currentClinicLocation.id ? auth.currentClinicLocation.id : '',
      },
      summary: summary,
      auto_assign: false,
      doctor: {
        id: selectedDoctor ? Number(selectedDoctor) : '',
      }
    }
    bookConsultation(consultationObject)
  }


  renderGreenTick = () => {
    return (
      <div className={css.green_tick_container}>
        <GreenTick className={css.green_tick_container_image} />
      </div>
    )
  }

  renderAcknowledgement = () => {
    return (
      <div className={css.acknowledgementContainer}>
        {this.renderGreenTick()}
        <p className={css.acknowledgementContainer_text}>
          Thanks! Click ‘Done’ to go to the next ticket.
        </p>
      </div>
    )
  }

  render() {
    const { ticketing } = this.props;
    const { bookConsultationLoading, bookConsultationResponse } = ticketing;
    return (<div>
      {bookConsultationLoading
        ?
        <>
          <ProgressIndicator />
        </>
        :
        <>{bookConsultationResponse === apiStatus.SUCCESS ? <>
          {this.renderAcknowledgement()}
        </> : <> Something went wrong !! </>
        }</>}
    </div>)
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, ticketing: state.ticketing };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCommentsToTicket: (ticketId, reason, comment) => { dispatch(ticketingActions.addCommentsToTicketEvent(ticketId, reason, comment)) },
    bookConsultation: (consultationObject) => { dispatch(consultationActions.bookConsultationEvent(consultationObject)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceConsultation);