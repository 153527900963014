import React from 'react';
import { connect } from 'react-redux';

import css from './style.module.scss';

class TextBox extends React.Component {

  handleChange = (event) => {
    const { onChange } = this.props;
    onChange(event.target.value);
  }

  render() {
    const { val } = this.props;
    return (
      <div className={css.container}>
        <textarea className={css.container_input} name="input" cols="40" rows="5"
          onChange={this.handleChange}
          value={val}
        >
          {/* {val} */}
        </textarea>
      </div>
    )
  }
}

export default connect(null, null)(TextBox);